@import '~styles/mixins.sass';

.history-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-left: 0px;
	height: 50%; }

.history-content {
	@include display-flex-center-mixin;
	width: 9.2vw;
	cursor: pointer;
	height: 100%; }

.history-name {
	padding-left: 5px;
	cursor: pointer; }

.history-image {
	width: 24px;
	height: 24px; }
