@import '~styles/mixins.sass';

.mvp-popup-container {
	width: 46.12vw;
	min-height: 15vh; }

.header-container {
	width: 100%;
	min-height: 12.24vh;
	position: relative; }


.content-container {
	width: 100%;
	height: 41vh;
	// height: calc(100% - 60px)
	position: relative;
	box-sizing: border-box;
	padding: 1vh 1vw 4vh 1vw;
	display: flex;
	flex-direction: column;
	align-items: end; }

.content-container2 {
	width: 100%;
	height: 13vh;
	position: relative;
	box-sizing: border-box;
	padding: 1vh 1vw 4vh 1vw;
	display: flex;
	flex-direction: column;
	align-items: end; }

.correct-help-container2 {
	width: 100%;
	height: 100%; }

.correct-help-text2 {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center; }

.cards-container {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	display: flex;
	// flex-direction: column
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.8vw; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.header-content-container {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	padding: 20px 15px;
	box-sizing: border-box; }

@media only screen and (max-height: 760px) {
	.header-content-container {
		padding: 1vh 0vw 1vh 0; } }

.header-details-container {
	width: 100%;
	height: 100%;
	// @include display-flex-center-mixin
	flex-direction: column;
	padding: 0 1vh;
	box-sizing: border-box; }

.heading-content {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: 5px; }

.desc-content {
	width: 100%;
	text-align: left;
	margin-bottom: 1vh; }

.heading-text {
	padding-right: 10px;
	box-sizing: border-box; }

.correct-help-container {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #DADADA;
	border-radius: 2px;
	padding-right: 0.2vw;
	width: 10.176vw;
	margin-bottom: 1vh; }

.correct-help-img {
	width: 24px;
	height: 24px; }

.correct-help-text {
	padding-left: 0 0.1vw 0 0.1vw;
	box-sizing: border-box; }

.close-container {
	width: 10%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 5px 0;
	box-sizing: border-box; }

.close-icon {
	width: 26px;
	height: 26px; }
