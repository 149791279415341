@import '~styles/mixins.sass';
.container {
	height: 100%;
	@include display-flex-center-mixin; }

.wrapper {
	width: 74vw; }

.description {
	text-align: left;
	width: 46vw;
	padding-right: 6px; }

.description-steps-container {
	padding: 3.125vh 1.757vw;
	margin: 30px;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 18vh;
	backdrop-filter: blur(5px); }

.steps-container {
	width: 17.58vw; }

.button-container {
	display: flex;
	flex-direction: column;
	width: 24.451vw;
	margin: auto; }

.button-next {
	display: flex;
	flex-direction: column;
	width: 11.713vw;
	margin: auto; }

.walkthrough-background {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0; }

.seperation-line2 {
	width: 2px;
	height: 110px;
	margin-right: 1vw; }
