@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.button-style {
    @extend %box-sizing;
    @include display-flex-center-mixin;
    width: fit-content;
    cursor: pointer;
    margin-top: 2px;
    padding: 8px 16px 8px 16px;
    text-transform: uppercase;
    border-radius: 4px; }
