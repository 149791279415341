@import '~styles/mixins.sass';

.tabs-container {
	width: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	margin-bottom: 4vh; }

.tab-line-container {
	@include display-flex-start; }

.tab-seperation-line {
	width: 6vw;
	height: 1px; }
