@import '~styles/mixins.sass';

.big {
    width: 32.3vw;
    height: 8.854vh;
    background: #FFFFFF;
    border-radius: 1px;
    margin: 1vh 0px 1vh 0px;
    position: relative;
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: 4px; }

.name-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5vh 0px 0px 8px; }

.name {
    padding-right: 5px; }

.completion {
    line-height: 4vh;
    padding: 0px 0px 0px 8px; }

.graph-box {
    padding: 5px 0px 0px 8px; }

.graph {
    background: #232882;
    opacity: 0.1;
    border-radius: 2px;
    height: 4px;
    width: 380px; }

.graph-overlay {
    background: rgba(35,40,130,0.9);
    border-radius: 2px;
    height: 4px;
    margin-top: -4px; }

.small {
    width: 10.168vw;
    height: 8.594vh;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #989898;
    position: relative;
    box-sizing: border-box; }

.small-child {
    width: 90%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); }

.mb {
    height: 11.198vh;
    .b-change {
        bottom: 1vh;
        right: 1.8vw; } }

.small-boxes-bottom {
    justify-content: space-between; }

.small-boxes {
    display: flex;
    margin-bottom: 0.8vh;
    width: 32.4vw;
    gap: 0.91vw; }

.big-box {
    display: flex; }

.re-box-image {
    width: 1.757vw;
    height: 3.125vh; }

.met-name {
	width: 32.4vw;
	display: flex;
	height: 4.427vh;
	padding: 4px 16px 4px 16px;
	border-radius: 4px;
	box-sizing: border-box {
    display: flex;
    align-items: center; } }

.arrow {
    width: 0.878vw;
    height: 0.781vh;
    padding-top: 2px;
    padding-right: 3px; }

.arrow-small {
    width: 0.878vw;
    height: 0.781vh;
    padding-top: 2px;
    padding-right: 3px; }

.b-change-small {
    display: flex;
    position: absolute;
    bottom: 14px;
    right: 8px;
    font-size: 0.625em;
    color: rgba(35,40,130, 0.7);
    @include display-flex-center-mixin; }

.b-change {
    display: flex;
    position: absolute;
    bottom: 2.446vw;
    right: 2.294vh;
    font-size: 0.625em;
    color: rgba(35,40,130, 0.7);
    border-radius: 16px;
    padding-right: 3px;
    @include display-flex-center-mixin; }

.re-box {
    background: white;
    border-radius: 4px;
    width: 15.596vw;
    height: 8.594vh;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 0.5vh;
    box-sizing: border-box;
    border: 1px solid #989898; }

.re-completion {
    padding: 0px 0px 0px 1.025vw;
    margin-top: -0.5vh; }

.re-name-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 0px 0px 1.025vw; }

.re-name {
    padding-right: 5px; }

.value-red {
    color: #D11021;
    box-sizing: border-box; }

.value-green {
    color: #0B7F3D;
    box-sizing: border-box; }

.sim-mvp-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    height: 100%; }

.sim-mvp-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    width: 100%;
    height: 100%; }

.sim-mvp-details {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px; }

.sim-mvp-top {
    width: 100%;
    display: flex; }

.sim-mvp-text {
    padding-right: 5px; }

.sim-mvp-value {
    margin-right: 6px; }

.sim-mvp-bottom {
    width: 100%; }

.sim-mvp-progress-bar-container {
    width: 100%;
    border-radius: 4px; }

.sim-mvp-progress-bar {
    height: 7px; }

.sim-mvp-progress-bar {
	height: 7px;
	border-radius: 3px;
	display: flex; }

.sim-mvp-arrow-container {
    display: flex; }
