.legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    box-sizing: border-box; }

.wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 2vh 0.65vw 1vh 0.65vw;
    height: 10.026vh;
    margin-top: 1vh;
    box-sizing: border-box;
    border-radius: 4px; }

.sim-mvp-progress-bar-container {
    width: 100%;
    height: 3vh;
    text-align: right;
    position: relative;
    padding-right: 1vw;
    padding-bottom: 1vh;
    box-sizing: border-box; }

.sim-mvp-progress-bar {
    height: 3vh;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 1vw;
    box-sizing: border-box;
    padding-left: 0.3vw;
    padding-bottom: 1vh; }

.graph-progress-bar {
    width: 100%; }

.graph {
    width: 100%;
    box-sizing: border-box; }

.square {
    width: 12px;
    height: 12px;
    margin-right: 4px; }

.leg-wrap {
    padding-bottom: 7px;
    display: flex;
    margin-left: 12px; }

.green {
    background: #B5F000; }

.white {
    background: #FFFFFF; }

.grey {
    background: #474747B2; }
