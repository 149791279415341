@import '~styles/mixins.sass';

.main-component {
	width: 814px;
	height: calc(100% - 38px);
	position: relative; }

.header-container {
	border-radius: 0;
	padding: 24px 30px 16px 30px;
	display: flex;
	justify-content: space-between; }

.close-icon {
	position: absolute;
	right: 2vw;
	z-index: 1;
	top: 2.3vh; }

.popup-body {
	padding: 12px 24px;
	height: calc(100% - 240px);
	overflow: auto; }

.card-style {
	padding: 20px;
	margin: 16px 0; }

.card-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px; }

.tm-member-details {
	display: flex; }

.name-details {
	margin-left: 10px; }

.footer-container {
	// position: absolute
	display: flex;
	align-items: center;
	bottom: 10px;
	padding: 5px 15px;
	box-sizing: border-box;
	height: 170px; }

.footer-image {
	width: 54px;
	height: 54px;
	min-width: 54px;
	margin-right: 15px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center; }

.cpo-details {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; }
