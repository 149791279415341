@import '~styles/mixins.sass';

.container {
	@include display-flex-center-mixin;
	width: 100%;
	min-height: 38.021vh;
	box-sizing: border-box;
	position: relative;
	//padding: 0 6.116vw 0 6vw
	box-sizing: border-box;
	top: 0;
	left: 0;
	@extend %row; }

.image-container {
	@include display-flex-center-mixin;
	width: 25%;
	height: 28.516vh;
	border-radius: 1px;
	box-sizing: border-box; }

.image {
	width: 21.318vw;
	height: 28.516vh; }

.response-container {
	width: 75%;
	height: 100%;
	box-sizing: border-box; }

.person-name {
	width: 80%;
	height: 100%;
	box-sizing: border-box; }

.response-message {
	width: 100%;
	box-sizing: border-box; }

.close-container {
	position: absolute;
	bottom: 4vh;
	right: 2.5vw; }

.close-container .continue {
	width: 50px; }
