@import '~styles/mixins.sass';

.info-container {
    width: 0.7vw;
    height: 1.5vh;
    position: relative;
    padding-right: 2px; }

.info-icon {
    width: 100%;
    height: 100%; }

.tooltip-container-right {
    width: 250px;
    position: absolute;
    top: -5px;
    left: 20px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.tooltip-container-left {
    width: 250px;
    position: absolute;
    top: -5px;
    left: -250px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.tooltip-container-top {
	width: 12vw;
	position: absolute;
	top: 14px;
	left: -5.6vw;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center {
    font-size: 0.7em; } }

@media only screen and (max-height: 760px) {
    .tooltip-container-top {
    	width: 12vw {
        align-text: right; } } }

.tooltip-container-bottom {
	width: 250px;
	position: absolute;
	top: 18px;
	left: -116px;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; }

.tooltip-arrow-left-right {
	width: 0;
	height: 0;
	margin-top: 8px; }

.tooltip-arrow-top-bottom {
	width: 0;
	height: 0; }

.tooltip-content {
    box-sizing: border-box;
    padding: 7px;
    width: calc(100% - 7px);
    // display: flex
    // justify-content: flex-start
    // align-items: center
    text-align: left; }
