@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.button-style {
    @extend %box-sizing;
    @include display-flex-center-mixin;
    min-width: 10.474vw;
    width: fit-content;
    cursor: pointer;
    padding: 1.042vh 1.171vw 1.042vh 1.171vw;
    height: 40px;
    text-transform: uppercase;
    border-radius: 4px; }
