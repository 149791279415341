@import '~styles/mixins.sass';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; }

.modalContent {
  background: white;
  border-radius: 5px;
  text-align: center;
  position: relative;
  width: 49.634vw;
  height: 39.063vh; }

.header {
  background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
  border-radius: 5px 5px 0 0;
  color: white;
  padding-top: 1.5vh;
  height: 17.969vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.iconContainer {
  display: flex;
  align-items: center;
  height: 7vh; }

h2 {
  font-size: 1em; }

p {
  width: 37vw;
  height: 5vh;
  text-align: center; }

.modalActions {
  padding-top: 2.2vh;
  display: flex;
  justify-content: center;
  gap: 3vh; }
