body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: rgba(0, 0, 0, 0.7);
	font-size: "1.171vw";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 0.29vw;
	/* border-radius: 8px; */
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(152, 152, 152, 0.5);
	/* border-radius: 8px; */
	box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(152, 152, 152, 1);
	/* border-radius: 8px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.6);
}

.flex-box-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.base-app-box {
	width: 100%;
	height: 100vh;
	min-width: 1270px;
	min-height: 600px;
	/* -webkit-transform:translate3d(0,0,0); */
	position: relative;
	overflow: hidden;
	-webkit-touch-callout: none;
	/* iOS Safari*/
	-webkit-user-select: none;
	-webkit-user-drag: none;
	/* Chrome/Safari/Opera*/
	-khtml-user-select: none;
	-khtml-user-drag: none;
	/* Konqueror*/
	-moz-user-select: none;
	-moz-user-drag: none;
	/* Firefox*/
	-ms-user-select: none;
	-ms-user-drag: none;
	/* Internet Explorer/Edge*/
	user-select: none;
}


/* React joyride tooltip */
.react-joyride__spotlight {
	/* --padding: 8px 8px; */
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	/* padding: var(--padding); */
	background-clip: content-box;
	box-shadow: inset 0 0 0 var(--padding) rgba(0, 0, 0, 0);
	box-sizing: border-box;
	background-color: #FD1E510C;
}

.react-joyride__tooltip {
	width: auto !important;
	max-width: 700px !important;
	padding: 0 5px !important;
	box-shadow: 2px 2px 4px 0px #00000033 !important;
	/* background-image: url('https://knolskape-website.s3.amazonaws.com/misc/suyog_patil/2019/07/18/73/rectangle_4.4.svg') !important; */
	background-size: cover;

}

/* .react-joyride__tooltip div:nth-child(1) {text-align: left !important;	
}

.react-joyride__tooltip div:nth-child(1) div {
	padding: 10px !important;
	padding-bottom: 20px !important;
}*/

.react-joyride__tooltip div:nth-child(2) {
	margin-top: 0px !important;
	padding-bottom: 0.5vw
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-primary"] {
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-back"] {
	/* display: none !important */
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__overlay {
	cursor: default !important;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.loader_loader-container__3jmMB {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.loader_svg-container__1cQQa {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loader_loadingMove__19UCR 3s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.loader_svg-wrapper__32P_F {
  width: 100%;
  height: 100%; }

@keyframes loader_loadingMove__19UCR {
  0%, 100% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(180deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.bundleLoader_container__2lILl {
  width: 100vw;
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopHeader_header-container__3SW0x {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@media only screen and (min-width: 1280px) {
  .desktopHeader_header-content__1un8W {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .desktopHeader_header-content__1un8W {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .desktopHeader_header-content__1un8W {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.desktopHeader_header-container__3SW0x {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8.3vh;
  min-width: 1280px;
  padding-right: 5.75vw;
  padding-left: 2.2vw;
  position: relative; }

.desktopHeader_header-content__1un8W {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 100%; }

.desktopHeader_left-header__1EbXy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0; }

.desktopHeader_middle-header__2L7XB {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  margin-left: 2vw; }

.desktopHeader_right-header__38JSL {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  grid-gap: 0.4vw;
  gap: 0.4vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simLogo_sim-logo-container__6ryVW {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.simLogo_sim-logo__2SgxW {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.simLogo_sim-name__3o2lB {
  padding-left: 5px; }

.simLogo_sim-image__2wU6u {
  width: 17.7vw;
  height: 6.881vh;
  margin-left: 6.881vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simUserProfile_tooltip-container-bottom__AeteA {
  width: 18.302vw;
  position: absolute;
  top: 5.8vh;
  left: -29vw;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.simUserProfile_tooltip-arrow-top-bottom__1_JYm {
  width: 0;
  height: 0;
  margin-top: 8px; }

.simUserProfile_tooltip-content__1Yf8D {
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
  text-align: left; }

.simUserProfile_info-icon__3mqhx {
  width: 100%;
  height: 100%; }

.simUserProfile_user-profile-container-with-options__2EK3Y {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none; }

.simUserProfile_user-profile-container__26WE5 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.simUserProfile_user-profile-image-container__4ikRq {
  width: 2vw;
  height: 2vh; }

.simUserProfile_user-profile-arrow-container__19khH {
  margin-left: 0.4vw;
  width: 0.777vw;
  height: 2.446vh; }

.simUserProfile_user-profile-option-container__1FF_r {
  position: absolute;
  width: 13.836vw;
  height: 4.2vw;
  z-index: 50;
  top: 5.3vh;
  left: -11vw;
  overflow: hidden;
  padding-top: 0.3vh; }

.simUserProfile_each-option__1m8iF {
  width: 100%;
  padding: 1vh 1vw;
  box-sizing: border-box; }

.simUserProfile_user-name-container__1DkUN {
  height: 4vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5vh 1vw; }

.simUserProfile_user-name__3Q4WB {
  height: 50%;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  word-break: break-all; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userProfile_main-component__3i0hP {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.upArrow_main-component__X3M2V {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.downArrow_main-component__3jd0T {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.confirmLogout_modalOverlay__2HsrV {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; }

.confirmLogout_modalContent__ljs2Q {
  background: white;
  border-radius: 5px;
  text-align: center;
  position: relative;
  width: 49.634vw;
  height: 39.063vh; }

.confirmLogout_header__1VAuK {
  background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
  border-radius: 5px 5px 0 0;
  color: white;
  padding-top: 1.5vh;
  height: 17.969vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.confirmLogout_body__3tO8z {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.confirmLogout_iconContainer__hsCw6 {
  display: flex;
  align-items: center;
  height: 7vh; }

h2 {
  font-size: 1em; }

p {
  width: 37vw;
  height: 5vh;
  text-align: center; }

.confirmLogout_modalActions__L5h02 {
  padding-top: 2.2vh;
  display: flex;
  justify-content: center;
  grid-gap: 3vh;
  gap: 3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.filledButton_button-style__oTK5B {
  box-sizing: border-box; }

.filledButton_button-style__oTK5B {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10.474vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 1.042vh 1.171vw 1.042vh 1.171vw;
  height: 40px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.logoutArrow_main-component__36wOx {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.infoComponent_info-container__2O9LO {
  width: 0.7vw;
  height: 1.5vh;
  position: relative;
  padding-right: 2px; }

.infoComponent_info-icon__1lp7N {
  width: 100%;
  height: 100%; }

.infoComponent_tooltip-container-right__1jqkT {
  width: 250px;
  position: absolute;
  top: -5px;
  left: 20px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-left__1QeaI {
  width: 250px;
  position: absolute;
  top: -5px;
  left: -250px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-top__1uvIY {
  width: 12vw;
  position: absolute;
  top: 14px;
  left: -5.6vw;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    align-items-font-size: 0.7em; }

@media only screen and (max-height: 760px) {
  .infoComponent_tooltip-container-top__1uvIY {
    width: 12vw;
      width-align-text: right; } }

.infoComponent_tooltip-container-bottom__2252d {
  width: 250px;
  position: absolute;
  top: 18px;
  left: -116px;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.infoComponent_tooltip-arrow-left-right__3CMD0 {
  width: 0;
  height: 0;
  margin-top: 8px; }

.infoComponent_tooltip-arrow-top-bottom__XoJxV {
  width: 0;
  height: 0; }

.infoComponent_tooltip-content__DVOAB {
  box-sizing: border-box;
  padding: 7px;
  width: calc(100% - 7px);
  text-align: left; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.infoIcon_main-component__2_GWO {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.timer_timer-container__2aPJP {
  height: 65%;
  width: 5.887vw;
  margin-right: 0.4vw;
  margin-left: 0.4vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }

.timer_timer-content__2iFHN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px 5px 5px;
  box-sizing: border-box;
  text-align: center;
    text-align-overflow: hidden;
    text-align-white-space: nowrap; }

.timer_timer-content-ar__1g3d7 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simObjectives_objectives-container__fvAWc {
  height: 5.199vh;
  width: 8.39vw;
  padding: 8px 0.5vw 8px 0.5vw;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  justify-content: space-evenly;
  grid-gap: 3px;
  gap: 3px;
  margin-right: 0.5vw; }

.simObjectives_objectives-image-container__37czd {
  width: 1.464vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simObjectives_objectives-image__OxKO5 {
  width: 1.318vw;
  height: 2.344vh; }

.simObjectives_objectives-text-container__3cJ_r {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.headerSeparation_separation__133d8 {
  width: 2px;
  height: 5.3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.objectives_main-component__3dfBi {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simLeaderBoard_leaderboard-container__32Rcd {
  height: 5.199vh;
  width: 35%;
  padding: 0.5vh 0.5vw;
  box-sizing: border-box;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  justify-content: space-evenly;
  margin-right: 0.6vw;
  margin-left: 0.5vw; }

.simLeaderBoard_leaderboard-image-container__3IT7- {
  width: 1.318vw;
  height: 2.344vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.simLeaderBoard_leaderboard-image__3pVmq {
  width: 1.318vw;
  height: 2.344vh; }

.simLeaderBoard_leaderboard-text-container__TSKWO {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.leaderboard_main-component__3hS_z {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simMVP_sim-mvp-container__38TeH {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 5.199vh;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-right: 1vw;
  padding: 0 0.2vw;
  border-radius: 8px; }

.simMVP_mvp-down-arrow__2y-ra {
  position: absolute;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  top: 100%;
  left: 0;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: simMVP_showDownArrow__11ZZH 0.5s 1; }

.simMVP_down-arrow__9lnGz {
  width: 10px;
  height: 5px; }

.simMVP_sim-mvp-content__21vS2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0vw 4px 0px;
  height: 100%;
  box-sizing: border-box; }

.simMVP_sim-mvp-details__178xP {
  width: 9.883vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start; }

.simMVP_sim-mvp-top__3bfGm {
  width: 10.321vw;
  height: 55%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box; }

.simMVP_sim-mvp-text__t2Doh {
  padding-right: 0.4vw; }

.simMVP_sim-mvp-value__2LWwd {
  margin-right: 0.4vw; }

.simMVP_sim-mvp-bottom__2OLft {
  width: 9.883vw;
  height: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2px;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow-container__1DofP {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow__1QMqy {
  width: 5px;
  height: 5px; }

.simMVP_sim-mvp-progress-bar-container__2J6W9 {
  width: 100%;
  height: 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  overflow: hidden; }

.simMVP_sim-mvp-progress-bar__1lHMi {
  height: 7px;
  border-radius: 3px;
  display: flex; }

@keyframes simMVP_showDownArrow__11ZZH {
  from {
    top: 80%;
    opacity: 0; }
  to {
    top: 100%;
    opacity: 1; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simProgress_sim-progress-container__123kV {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0px 12px 0vw;
  box-sizing: border-box;
  height: 5.199vh; }

.simProgress_sim-progress-child__10gil {
  width: 23.4vw; }

.simProgress_sim-progress-child__10gil {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.simProgress_sim-progress-content__vOIjd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0.3vw 0px 0.8vw;
  margin-top: 5px;
  position: relative;
  height: 5.199vh; }

.simProgress_progress-start-text__2pmIs {
  padding-right: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.simProgress_progress-end-text__2pCAb {
  padding-left: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px; }

.simProgress_progress-container__1mLne {
  display: flex; }

.simProgress_end-days-container__2scVv {
  width: 2px;
  height: 10px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_middle-days-container__27zit {
  width: 2px;
  height: 6px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_current-days-container__113Ud {
  width: 2px;
  height: 8px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_day-tag-container__2uTzG {
  position: absolute;
  width: 45px;
  height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }

.simProgress_sprint-section-container__3EiIw {
  width: 28%; }

.simProgress_sprint-container__3SJB8 {
  display: flex;
  width: 4.3vw;
  height: 1.8vh;
  box-sizing: border-box;
  padding: 2px;
  justify-content: space-evenly;
  grid-gap: 3px;
  gap: 3px;
  margin-right: 1px; }

.simProgress_progress-value__3aeJ9 {
  display: flex;
  margin-right: 8px; }

.simProgress_deadline-bar-container__1a2BK {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  grid-gap: 5px;
  gap: 5px; }

.simProgress_deadline-container__20FBv {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.desktopFooter_footer-container__3o1nD {
  width: 100%;
  height: 2.995vh;
  position: absolute;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopFooter_sim-name__1aB_5 {
  padding: 0 4px; }

.desktopFooter_sim-tag__26phT {
  padding: 0 4px; }

.desktopFooter_knolskape-logo__2jSxx {
  padding: 0 2px 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.desktopFooter_footer-bar__1bop6 {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.planningWalkthrough_container__3Wffl {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.planningWalkthrough_wrapper__3SwbB {
  width: 74vw; }

.planningWalkthrough_description__1z1E2 {
  text-align: left;
  width: 46vw;
  padding-right: 6px; }

.planningWalkthrough_description-steps-container__17qrI {
  padding: 3.125vh 1.757vw;
  margin: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18vh;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.planningWalkthrough_steps-container__1oge5 {
  width: 17.58vw; }

.planningWalkthrough_button-container__3_dvi {
  display: flex;
  flex-direction: column;
  width: 24.451vw;
  margin: auto; }

.planningWalkthrough_button-next__1GAJu {
  display: flex;
  flex-direction: column;
  width: 11.713vw;
  margin: auto; }

.planningWalkthrough_walkthrough-background__2gMcz {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

.planningWalkthrough_seperation-line2__26W6l {
  width: 2px;
  height: 110px;
  margin-right: 1vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughTabs_tabs-container__8cFII {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh; }

.walkthroughTabs_tab-line-container__ijLVL {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.walkthroughTabs_tab-seperation-line__wvMsU {
  width: 6vw;
  height: 1px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughTab_tab-container__2vCYB {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3vw;
  gap: 0.3vw;
  height: 4.948vh;
  width: 11vw;
  box-sizing: border-box;
  border-radius: 4px; }

.walkthroughTab_symbol-container__vusoH {
  display: flex;
  align-items: center;
  width: 1.171vw;
  height: 2.283vh; }

.walkthroughTab_tab-name__2FylA {
  margin-bottom: 2px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.circleTick_main-component__2RoCq {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.hollowCircle_main-component__1fFpt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.filledCircle_main-component__2ksy- {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughSteps_container__6TBTG {
  box-sizing: border-box; }

.walkthroughSteps_seperation-line__3TtF2 {
  width: 1px;
  height: 1.8vh;
  margin: 2px 1.6vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughStep_container__kGykQ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 17.57vw; }

.walkthroughStep_number__1lx4P {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.724vw;
  height: 1.724vw;
  padding-bottom: 0.4vh;
  box-sizing: border-box; }

.walkthroughStep_check-container__IDImf {
  width: 1.724vw;
  height: 1.724vw; }

.walkthroughStep_title__2b4l7 {
  margin-left: 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskButtonHollow_main-component__3LirD {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.executionWalkthrough_container__1agpH {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.executionWalkthrough_wrapper__IaSL1 {
  width: 100%; }

.executionWalkthrough_description__1Sp83 {
  margin: 25px 100px 20px 100px;
  text-align: center; }

.executionWalkthrough_sprints-container__2qkfO {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh; }

.executionWalkthrough_button-container__NvCTy {
  display: flex;
  flex-direction: column;
  width: 24.451vw;
  margin: auto; }

.executionWalkthrough_walkthrough-background__3B8-O {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintsWalkthrough_container__2mTB6 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2vw;
  gap: 2vw;
  margin: 8px 0px 25px 0px;
  overflow: hidden; }

.sprintsWalkthrough_seperation-line__Dyz4m {
  width: 1px;
  height: 12px;
  margin: 2px 14px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintWalkthrough_container__2Cx_z {
  width: 14.641vw;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding-bottom: 2vh; }

.sprintWalkthrough_disabled-container__2AzUq {
  zoom: 0.92; }

.sprintWalkthrough_rotate-image__2NS_- {
  width: 135px;
  height: 135px;
  position: absolute;
  top: 11px;
  left: 15px;
  opacity: 0.5; }

.sprintWalkthrough_title__jv1Tp {
  width: 85%;
  height: 4.427vh;
  padding: 0vh 0.586vw 0.621vh 0.586vw;
  box-sizing: border-box;
  margin: auto;
  margin-top: 2vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWalkthrough_steps-container__3JQCb {
  margin: 1.7vh 0 0 2vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.overlay_complete-container__36mg3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 50; }

.overlay_bottom-container__3AGle {
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.overlay_bottom-container-component__3fO5V {
  min-height: 100px;
  width: 100%; }

.overlay_right-container__3XKE6 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }

.overlay_center-container__1GWCF {
  display: flex;
  justify-content: center;
  align-items: center; }

.overlay_right-container-component__3Lifn {
  min-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.actionResponse_container__2EiKN {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponse_container__2EiKN {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 38.021vh;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0; }

.actionResponse_image-container__JL9d1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 28.516vh;
  border-radius: 1px;
  box-sizing: border-box; }

.actionResponse_image__2avY7 {
  width: 21.318vw;
  height: 28.516vh; }

.actionResponse_response-container__1aB-v {
  width: 75%;
  height: 100%;
  box-sizing: border-box; }

.actionResponse_person-name__2Av7Z {
  width: 80%;
  height: 100%;
  box-sizing: border-box; }

.actionResponse_response-message__1Bmi- {
  width: 100%;
  box-sizing: border-box; }

.actionResponse_close-container__23XMU {
  position: absolute;
  bottom: 4vh;
  right: 2.5vw; }

.actionResponse_close-container__23XMU .actionResponse_continue__36Uro {
  width: 50px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponseMetrics_metrics-container__mgIjh {
  width: 100%;
  margin-top: 3vh; }

.actionResponseMetrics_top-metrics-container__3ac3N {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_left-metric-container__2-3X2 {
  min-height: 67px;
  border-radius: 18px;
  margin: 0 1vh;
  box-sizing: border-box; }

.actionResponseMetrics_left-metrics-value-change-container__sfdy0 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px; }

.actionResponseMetrics_metrics-image__1Rdg_ {
  width: 1.171vw;
  height: 2.083vh;
  margin-right: 8px; }

.actionResponseMetrics_bottom-metrics-container__QRdSO {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  margin-left: 15.4vw; }

.actionResponseMetrics_bottom-metrics-wrapper__3jwOJ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  box-sizing: border-box; }

.actionResponseMetrics_right-metric-container__3QlkL {
  padding: 0 1vh; }

.actionResponseMetrics_metric-title__8LstJ {
  text-transform: uppercase; }

.actionResponseMetrics_right-metrics-value-change-container__2ECu8 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_metrics-value__3jjpw {
  margin-right: 0.878vw; }

.actionResponseMetrics_metrics-change__JXBko {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.294vw;
  min-height: 2.604vh;
  padding: 0 0.8vh;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center; }

.actionResponseMetrics_arrow-container__2aIQo {
  width: 1.171vw;
  height: 2.083vh;
  margin-right: 3px; }

.actionResponseMetrics_mvp-title-value-container__16zG1 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_mvp-value__vo8kX {
  margin-left: 0.878vw; }

.actionResponseMetrics_sim-mvp-progress-bar-container__2j4Q3 {
  width: 100%;
  height: 6px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_sim-mvp-progress-bar__P9mV4 {
  height: 6px;
  border-radius: 2px;
  display: flex; }

.actionResponseMetrics_game-metric-container__2dd7b {
  padding: 0 2.196vw;
  box-sizing: border-box; }

.actionResponseMetrics_game-metrics-container__2XFlZ {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponseMetrics_metric-container__JhUog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.5vh;
  margin-right: 1.464vw;
  padding-right: 0.3vw; }

.actionResponseMetrics_metric-content__3uOdB {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  grid-gap: 3px;
  gap: 3px;
  height: 6.1vh;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px 6px; }

.actionResponseMetrics_metric-name-desc-container__bb_7I {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.actionResponseMetrics_metric-name-value-container__VG3wX {
  display: flex;
  flex-direction: column;
  align-itmes: start;
  justify-content: space-between;
  padding-top: 8px; }

.actionResponseMetrics_metric-name__2JaR6 {
  padding-right: 1px; }

.actionResponseMetrics_metric-image-container__2lPT1 {
  display: flex;
  height: 100%;
  align-items: center; }

.actionResponseMetrics_metric-image__21yj3 {
  width: 24px;
  height: 24px; }

.actionResponseMetrics_metric-value__3-Hl5 {
  padding-left: 0px;
  margin-top: -0.6vh; }

.actionResponseMetrics_metric-value-right__ww8lj {
  margin-right: 3px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.incrementArrow_main-component__3lxey {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.decrementArrow_main-component__LSFlr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.flagIcon_main-component__9LeFd {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintMetrics_sprint-container__1K9-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1.2vw;
  height: 6.3vh; }

.sprintMetrics_sprint-content__1O4o1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 14.2vw;
  height: 6vh;
  border-radius: 4px;
  padding: 7px 0px;
  box-sizing: border-box; }

.sprintMetrics_sprint-name-desc-container__3MbJ0 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintMetrics_sprint-name-day-container__JvP9F {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 3.6vw;
  height: 100%;
  padding-top: 2px; }

.sprintMetrics_sprint-name__yJVKJ {
  padding-right: 1px;
  font-size: 10px; }

.sprintMetrics_sprint-day-progress-container__Lfz0G {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%; }

.sprintMetrics_sprint-day-container__2CLa_ {
  font-size: 16px;
  margin-top: -5px; }

.sprintMetrics_sprint-progress-container__2DAai {
  padding-left: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintMetrics_sprint-day__1cdXa {
  width: 0.439vw;
  height: 100%;
  margin-right: 0.2vw;
  border-radius: 5px; }

.sprintMetrics_sprint-current-day__1Ys6v {
  width: 0.439vw;
  height: 100%;
  margin-right: 0.2vw;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.rightArrow_mainComponent__3ncnn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.budget_main-component__2nsPS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.skill_main-component__2cHrw {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.morale_main-component__10eei {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.custSat_main-component__2nlt9 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.event_container__3dW0U {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.event_container__3dW0U {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 38.021vh;
  box-sizing: border-box;
  position: relative;
  padding: 0px 1vw 10px 5vw;
  box-sizing: border-box;
  top: 0;
  left: 0; }

.event_image-container__1J4o6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 28.516vh;
  border-radius: 1px;
  box-sizing: border-box; }

.event_image__1mIvV {
  width: 21.318vw;
  height: 28.516vh; }

.event_response-container__3HA_j {
  width: 75%;
  padding: 0px 0px 3.906vh 0.952vw;
  box-sizing: border-box; }

.event_response-message__TlTVy {
  width: 100%;
  box-sizing: border-box;
  padding-right: 8vw; }

.event_person-name__17Umk {
  width: 80%;
  height: 100%;
  padding: 0px 30px 20px 0px;
  box-sizing: border-box; }

.event_close-container__QXHpk {
  position: absolute;
  bottom: 7vh;
  right: 3%; }

.event_close-container__QXHpk .event_continue__1U8gr {
  width: 50px; }

.event_metrics-container__3ooEq {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5.208vh;
  margin-right: 1.464vw;
  padding-right: 0.3vw;
  margin-top: 7vh; }

.event_left-metric-container__2DaL_ {
  min-height: 67px;
  border-radius: 18px;
  margin: 0 8px;
  padding: 10px 18px;
  box-sizing: border-box;
  min-width: 175px; }

.event_left-metrics-value-change-container__2SoIi {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px; }

.event_metric-title__3oUzu {
  text-transform: uppercase; }

.event_metrics-image__1sO8H {
  width: 1.171vw;
  height: 2.083vh;
  margin-right: 8px; }

.event_metrics-value__3kiYs {
  margin-right: 12px; }

.event_metrics-change__2Z1_g {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.058vw;
  height: 3.058vh;
  padding: 0 5px;
  border-radius: 12px;
  box-sizing: border-box; }

.event_arrow-container__2TBml {
  width: 1.171vw;
  height: 2.083vh;
  margin-right: 3px; }

.event_metric-container__jl2nX {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.5vh;
  min-width: 5.792vw;
  margin-right: 20px; }

.event_metric-content__3ZuhL {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  grid-gap: 3px;
  gap: 3px;
  height: 6.1vh;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px 6px; }

.event_metric-name-desc-container__YkEx2 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.event_metric-name-value-container__2ejLp {
  display: flex;
  flex-direction: column;
  align-itmes: start;
  justify-content: space-between;
  padding-top: 8px; }

.event_metric-name__3sA5a {
  padding-right: 1px; }

.event_metric-image-container__2CDHw {
  display: flex;
  height: 100%;
  align-items: center; }

.event_metric-image__2GyAj {
  width: 24px;
  height: 24px; }

.event_metric-value__3m4oN {
  padding-left: 0px;
  margin-top: -0.6vh; }

.event_metric-value-right__3QMEW {
  margin-right: 3px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.close_main-component__2REur {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.endSprintConfirmation_main-component__avmuD {
  width: 34.407vw;
  height: 24.219vh;
  border-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container__IanqG {
  width: 100%;
  height: 12.76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container-content__2Kpsf {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.endSprintConfirmation_popup-header-container-background__3coIP {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.endSprintConfirmation_popup-details-container__3JZ5Y {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_popup-buttons-container__3HnA4 {
  width: 100%;
  margin-top: 3vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_button-container__1XXRr {
  padding: 0 10px;
  width: 11.713vw;
  height: 5.208vh; }

.endSprintConfirmation_header-image__poApq {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.endSprintConfirmation_header-text__1aPsU {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.endSprintConfirmation_header-desc__3hMv3 {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.hollowButton_button-style__1zGKd {
  box-sizing: border-box; }

.hollowButton_button-style__1zGKd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-top: 2px;
  padding: 8px 16px 8px 16px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.triangleBackground_main-component__3L1Mn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.autofillSprintConfirmation_main-component__2YZ3I {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container__TAaEr {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container-content__17RdZ {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.autofillSprintConfirmation_popup-header-container-background__1LHqP {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.autofillSprintConfirmation_popup-details-container__UUlRz {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_popup-buttons-container__1CdY1 {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_button-container__2pZ7r {
  padding: 0 10px; }

.autofillSprintConfirmation_header-image__1tIpl {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.autofillSprintConfirmation_header-text__Vjucg {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.autofillSprintConfirmation_header-desc__1PTll {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprintConfirmation_main-component__2zRSb {
  width: 35.933vw;
  height: 28.44vh;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container__3VYqM {
  width: 100%;
  height: 14.985vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container-content__3dbDv {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintConfirmation_popup-header-container-background__1YZsw {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintConfirmation_popup-details-container__3llTT {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_popup-buttons-container__cYRzU {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_button-container__3XNv5 {
  padding: 0 10px; }

.replanSprintConfirmation_header-image__2Dbmx {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintConfirmation_header-text__ilIXX {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintConfirmation_header-desc__1uDDU {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprintHelpPopup_main-component__1q-1d {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container__2jlWA {
  width: 100%;
  height: 12.76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container-content__iVAw2 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintHelpPopup_popup-header-container-background__2fkPF {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintHelpPopup_popup-details-container__2mXsG {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_popup-buttons-container__H18on {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_button-container__3-pVC {
  padding: 0 10px; }

.replanSprintHelpPopup_header-image__3liTL {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintHelpPopup_header-text__1lu3m {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintHelpPopup_header-desc__3thDV {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskConfirmation_main-component__1LKUW {
  width: 49.634vw;
  height: 38.073vh;
  border-radius: 8px;
  overflow: hidden; }

.addTaskConfirmation_popup-header-container__15PRs {
  width: 100%;
  min-height: 18.234vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  padding: 25px;
  box-sizing: border-box; }

.addTaskConfirmation_popup-header-container-content__147-3 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 50px; }

.addTaskConfirmation_popup-header-container-background__p9v0N {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.addTaskConfirmation_popup-details-container__1FgEd {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_popup-buttons-container__2ePb4 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 3vw;
  gap: 3vw;
  padding: 1vh 0 1vh 0;
  box-sizing: border-box; }

.addTaskConfirmation_button-container__HXg3E {
  padding: 0 10px;
  width: 10.474vw;
  height: 40px; }

.addTaskConfirmation_header-image__1EcSL {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.addTaskConfirmation_header-text__1okSm {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0;
  word-break: break-all; }

.addTaskConfirmation_header-desc__1qOZc {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.addTaskConfirmation_details-container__1FVW8 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2vh 0 1vh 0; }

.addTaskConfirmation_tasks-container__3ojsG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_storyPoints-container__3kyNe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.storyPopup_story-popup-container__11NT5 {
  position: relative; }

.storyPopup_header-container__kD5Fh {
  width: 51.835vw;
  min-height: 14vh;
  position: relative; }

.storyPopup_header-without-story__K1GFT {
  min-height: 9vh;
  width: 59.327vw; }

.storyPopup_content-container__12PNa {
  width: 51.835vw;
  min-height: 20.052vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 20px; }

.storyPopup_content-container-blocker__1f_hb {
  min-height: 42.188vh;
  width: 59.327vw; }

.storyPopup_header-background-container__2EG7T {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.storyPopup_header-content-container__1H5hM {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px; }

.storyPopup_header-details-container__1xgEV {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.1vw; }

.storyPopup_story-name__XdlIw {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_story-details__aican {
  width: 100%;
  height: 20%;
  display: flex;
  margin-top: 1vh;
  justify-content: flex-start;
  align-items: center; }

.storyPopup_close-container__3IOGz {
  width: 5.857vw;
    width-height: 0.763vh;
  margin-bottom: 4vh;
  margin-right: 20px;
  border-radius: 2px; }

.storyPopup_close-container-blocker__1MVFv {
  width: 5.857vw;
    width-height: 0.763vh;
  margin-bottom: 1.8vh;
  margin-right: 20px;
  border-radius: 2px; }

.storyPopup_close-icon__2Il00 {
  width: 5.857vw;
    width-height: 0.763vh; }

.storyPopup_each-detail-container__WsXci {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.464vw; }

.storyPopup_each-detail-name__2ViAG {
  padding-right: 5px; }

.storyPopup_each-detail-value__1xTS_ {
  padding-right: 10px; }

.storyPopup_each-detail-info__1_9nb {
  width: 10px;
  height: 10px; }

.storyPopup_story-description-container__hnSVY {
  width: 75%;
  height: 35%;
  box-sizing: border-box;
  padding: 1.302vh 1.1vw; }

.storyPopup_story-history-container__2FoCr {
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  padding: 10px 1.1vw; }

.storyPopup_story-description-container-full__21dFx {
  width: 100%;
  height: 35%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-history-container-full___zY-M {
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  padding: 24px; }

.storyPopup_story-details-container__2Ia81 {
  width: 70%;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-actions-container__2FT0z {
  box-sizing: border-box;
  padding: 10px 0 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: -2.2vw;
  z-index: 2;
  overflow-y: auto; }

.storyPopup_story-description-heading__cJdti {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-description-content__1qbpC {
  width: 90%;
  text-align: left; }

.storyPopup_feature-description-heading__33aDI {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_feature-description-content__BeOVD {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-history-heading__10QvT {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  height: 16px; }

.storyPopup_story-history-content__IPMb9 {
  width: 100%;
  height: 100%;
  overflow-y: auto; }

.storyPopup_feature-image__2i3-C {
  width: 20px;
  height: 20px;
  margin-right: 10px; }

.storyPopup_selection-container__3p0Gk {
  box-sizing: border-box;
  position: absolute;
  bottom: 4vh;
  right: 1.5vw; }

.storyPopup_selection-content__A55gx {
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  width: 10.249vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.storyPopup_checkbox-container__3wSfa {
  width: 18px;
  height: 18px; }

.storyPopup_text-container__2--pK {
  padding-left: 4px; }

.storyPopup_card-tag__Hhb0d {
  border-bottom-right-radius: 2px; }

.storyPopup_card-tag__Hhb0d {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 1.1vw;
  padding-left: 0.1vw;
  padding-right: 0.2vw;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
  border-bottom-left-radius: 2px; }

.storyPopup_card-tag-image__1hKaH {
  width: 1.171vw;
  object-fit: cover; }

.storyPopup_card-tag-text__6RqOh {
  display: flex;
  line-height: 2.446vh;
  font-weight: 600;
  font-size: 0.875em; }

.storyPopup_story-select__2M3wi {
  position: absolute;
  top: 105px;
  left: 500px;
  width: 10.703vw;
  height: 4.893vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.storyPopup_story-select-icon__1Eozh {
  width: 11px; }

.storyPopup_story-select-text__2q4cu {
  font-size: 12px; }

.storyPopup_priority-container__1UvgS {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  border-radius: 2px;
  padding: 0px 0.293vw 0 0.293vw; }

.storyPopup_priority-text__26qK1 {
  display: flex;
  font-weight: 400;
  font-size: 0.625em; }

.storyPopup_priority-image-container__3TDdA {
  width: 1.171vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.storyHistoryItem_history-item-container__3YWXm {
  width: 100%;
  box-sizing: border-box;
  min-height: 17.6vh;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 370px; }

.storyHistoryItem_history-item-header__3aU-C {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  box-sizing: border-box; }

.storyHistoryItem_history-item-header-text__3xj90 {
  padding: 0 2px; }

.storyHistoryItem_history-item-name-container__21Hcb {
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px; }

.storyHistoryItem_history-item-name__3H-Xa {
  width: calc(100% - 19px); }

.storyHistoryItem_history-item-image__1DTFy {
  width: 19px;
  height: 19px; }

.storyHistoryItem_history-item-desc__uyu7w {
  width: 100%;
  text-align: left;
  padding-top: 5px; }

.storyHistoryItem_card-bottom-container__3yXO6 {
  display: flex;
  margin-bottom: 2.8vh;
  align-items: center; }

.storyHistoryItem_story-point-container__-zRy5 {
  display: flex; }

.storyHistoryItem_story-point-text__1g7c7 {
  display: flex; }

.storyHistoryItem_story-point-number__2tw_q {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.storyHistoryItem_priority-container__2y-He {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.083vh;
  margin-left: 1vw;
  padding: 1px 0.3vw 1px 0.3vw;
  border-radius: 2px; }

.storyHistoryItem_priority-text__WAEsL {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.blocker_main-component__hMU-R {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionList_actions-list-container__1psL0 {
  width: 16.5vw;
  padding-top: 1vh; }

.actionList_each-action-container__srGfE {
  margin-bottom: 1.302vh; }

.actionList_each-action-container__srGfE:last-child {
  overflow-y: hidden; }

.actionList_overlay__2v0BV {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.action_action-wrapper__3SDg4 {
  position: relative;
  top: 0;
  left: 0; }

.action_action-container__23X6W {
  width: 100%;
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 0.2vw;
  border-radius: 4px; }

.action_action-container-story__3B7yv {
  width: 12.738vw;
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 2.73px; }

.action_action-image-container__29J85 {
  width: 3.125vh;
  height: 3.125vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.action_action-image-story__10Ggt {
  width: 2.083vh;
  height: 2.083vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.action_action-image__DYYqZ {
  width: 20px;
  height: 20px; }

.action_action-name__K-2x5 {
  width: calc(100% - 36px);
  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.action_disable-action__3BZp3 {
  pointer-events: none;
  opacity: 0.5; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionOptionsPopover_popover-container__3KGkO {
  width: 39.45vw;
  border-radius: 4px;
  z-index: 100; }

.actionOptionsPopover_popover-container-story__1vQrV {
  width: 35.755vw;
  min-height: 31.078vh;
  border-radius: 4px;
  z-index: 100;
  top: 200px; }

@media (max-height: 700px) {
  .actionOptionsPopover_popover-container__3KGkO {
    top: 150px; } }

.actionOptionsPopover_popover-header__224NB {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box; }

.actionOptionsPopover_title-and-description__28uyK {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1.3vh 1.4vw; }

.actionOptionsPopover_title__3RhaD {
  margin-bottom: 3px;
  margin-top: 1vh; }

.actionOptionsPopover_close-container__29EdP {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  margin-right: 20px; }

.actionOptionsPopover_close-svg__3nlQo {
  width: 26px;
  height: 26px;
  cursor: pointer; }

.actionOptionsPopover_choose-options__3Ak4T {
  margin: 1.563vh 1.464vw  0 1.464vw; }

.actionOptionsPopover_options-container__20EW5 {
  margin: 0.5vh 1vw 0vh 1vw; }

.actionOptionsPopover_button-container__1OTjd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5vh 0; }

.actionOptionsPopover_popover-arrow__JV7Qh {
  position: absolute;
  width: 0;
  height: 0; }

.actionOptionsPopover_error-message__37aXg {
  margin: 20px;
  padding: 1vh 0.5vw 1vh 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionOption_option-container__2l_17 {
  display: flex;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.5vh 0.5vw;
  margin-bottom: 1vh;
  cursor: pointer; }

.actionOption_radio-container__KAKKk {
  width: 2.083vh;
  display: flex;
  align-items: flex-end;
  margin-bottom: 3vh;
  padding-right: 5px; }

.actionOption_option-details-container__1uCBp {
  width: 100%; }

.actionOption_radio-outline__3H_-r {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  margin-top: 5px; }

.actionOption_radio-inner-circle__1ssK6 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-sizing: border-box; }

.actionOption_option-effort-cost-container__13QwW {
  display: flex;
  justify-content: space-between; }

.actionOption_option-effort-cost-content__2FwOi {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionOption_option-effort-cost-title__3JgfH {
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.teamLunch_main-component__224RS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.standup_main-component__3qi_H {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.marketPulseSurvey_main-component__eNP1Y {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.rebuildFeature_main-component__3KOsi {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprint_main-component__2qZhe {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.retrospect_main-component__1KEtF {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.teamMotivationSurvey_main-component__1iDpd {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.updatePrd_main-component__3_gU4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userFeedback_main-component__3Oi8h {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userResearch_main-component__3Zjra {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.training_main-component__3KYVA {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.meetTheTeam_main-component__2QseN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mom_main-component__1M8wb {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.talkOnAgile_main-component__1HRwh {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.newActions_main-component__1tvMO {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskCheckBox_main-component__11W9T {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskCheckBox_main-component__Amn85 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskCheckBoxPopUp_main-component__2VxkD {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureSwim_main-component__vletr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureSafety_main-component__1HnmN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureOther_main-component__vTltN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureLounging_main-component__1tJYx {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureRiver_main-component__2OGRS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.must_main-component__14Lww {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.could_main-component__2JojI {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.should_main-component__21gYj {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mustStoriesCompletedPopup_main-component__2vkul {
  width: 49.634vw;
  min-height: 32.422vh;
  border-radius: 10px;
  overflow: hidden; }

.mustStoriesCompletedPopup_popup-header-container__TJXuG {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.mustStoriesCompletedPopup_popup-header-container-content__3HZRI {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.mustStoriesCompletedPopup_popup-header-container-background__WlnxQ {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.mustStoriesCompletedPopup_popup-details-container__2pvAw {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.mustStoriesCompletedPopup_popup-buttons-container__ajZl1 {
  width: 100%;
  height: 13.281vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.mustStoriesCompletedPopup_button-container__2nGx1 {
  padding: 0 10px;
  display: flex;
  grid-gap: 4vw;
  gap: 4vw; }

.mustStoriesCompletedPopup_header-image__1UKdn {
  padding: 1.5vh 1vw 1vh 1vw; }

.mustStoriesCompletedPopup_header-text__3Sk5h {
  text-align: center;
  width: 100%;
  box-sizing: border-box; }

.mustStoriesCompletedPopup_header-desc__3EEDA {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.mustStoriesCompletedPopup_popup-desc-container__27R2Y {
  box-sizing: border-box;
  padding: 1vh 3vw 0 3vw;
  text-align: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simMVPPopup_mvp-popup-container__28Kzs {
  width: 46.12vw;
  min-height: 15vh; }

.simMVPPopup_header-container__1i4My {
  width: 100%;
  min-height: 12.24vh;
  position: relative; }

.simMVPPopup_content-container__3BhNc {
  width: 100%;
  height: 41vh;
  position: relative;
  box-sizing: border-box;
  padding: 1vh 1vw 4vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: end; }

.simMVPPopup_content-container2__1bGW9 {
  width: 100%;
  height: 13vh;
  position: relative;
  box-sizing: border-box;
  padding: 1vh 1vw 4vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: end; }

.simMVPPopup_correct-help-container2__bDYGj {
  width: 100%;
  height: 100%; }

.simMVPPopup_correct-help-text2__2UQGt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simMVPPopup_cards-container__2rfd6 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 0.8vw;
  gap: 0.8vw; }

.simMVPPopup_header-background-container__24K6Q {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.simMVPPopup_header-content-container__XJmJI {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  box-sizing: border-box; }

@media only screen and (max-height: 760px) {
  .simMVPPopup_header-content-container__XJmJI {
    padding: 1vh 0vw 1vh 0; } }

.simMVPPopup_header-details-container__1rG8z {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 1vh;
  box-sizing: border-box; }

.simMVPPopup_heading-content__20fp8 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 5px; }

.simMVPPopup_desc-content__3Llq5 {
  width: 100%;
  text-align: left;
  margin-bottom: 1vh; }

.simMVPPopup_heading-text__1aa-i {
  padding-right: 10px;
  box-sizing: border-box; }

.simMVPPopup_correct-help-container__3gFWf {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DADADA;
  border-radius: 2px;
  padding-right: 0.2vw;
  width: 10.176vw;
  margin-bottom: 1vh; }

.simMVPPopup_correct-help-img__a7ocA {
  width: 24px;
  height: 24px; }

.simMVPPopup_correct-help-text__3Ktso {
  padding-left: 0 0.1vw 0 0.1vw;
  box-sizing: border-box; }

.simMVPPopup_close-container__1ZuwK {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 0;
  box-sizing: border-box; }

.simMVPPopup_close-icon__fG83K {
  width: 26px;
  height: 26px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.cross_main-component__2jpgG {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.taskCard_card-container__3C6UP {
  cursor: pointer;
  height: 20.642vh;
  box-sizing: border-box;
  padding: 1.432vh 0;
  margin: 0.1vw 0vw 1.302vh 0vw;
  position: relative; }

.taskCard_card-child__1bPdY {
  width: 96%;
  height: 91%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.taskCard_card-width__2gGlN {
  width: 21.154vw; }

.taskCard_card-cursor__2ljyy {
  cursor: pointer; }

.taskCard_card-popup__3UW1z {
  cursor: default; }

.taskCard_card-header__qxWIJ {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.366vw 0 0.366vw; }

.taskCard_card-heading-content__2dMug {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden; }

.taskCard_card-status-box__3r-px {
  width: 1.757vw;
  height: 3.125vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1.042vh;
  right: 0.586vw; }

.taskCard_desc-priority-container__2rZXJ {
  overflow: hidden; }

.taskCard_progress-container__2kFk6 {
  width: 100%;
  height: 1.042vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.366vw 0 0.366vw; }

.taskCard_progress-bar-background__3IUvD {
  width: 100%;
  height: 0.391vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px; }

.taskCard_progress-bar__BYb19 {
  max-width: 100%;
  height: 0.391vh;
  border-radius: 4px; }

.taskCard_story-point-container__22jyN {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.taskCard_story-point-text__1TWXa {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.taskCard_story-point-number__1BcVe {
  font-weight: 600;
  font-size: 0.625em; }

.taskCard_story-point-container-like-customer__3REvy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.878vw 0 0.878vw;
  box-sizing: border-box; }

.taskCard_story-point__2e616 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_story-point-icon__2Fvsq {
  display: flex;
  align-items: center; }

.taskCard_like-customer-img__-IEuW {
  width: 24px;
  height: 3.125vh; }

.taskCard_storypoints-text__FEvMb {
  padding-right: 10px; }

.taskCard_feature-details-container__1UknZ {
  padding: 0 12px;
  box-sizing: border-box; }

.taskCard_feature-container__59u1W {
  padding: 2px 6px 5px 6px;
  box-sizing: border-box; }

.taskCard_feature-image__3D_bG {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 2px; }

.taskCard_feature-name__3TXoA {
  padding-left: 5px;
  box-sizing: border-box; }

.taskCard_feature-desc__h7BAs {
  padding-left: 6px;
  box-sizing: border-box; }

.taskCard_add-task-button-container__ioOrD {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }

.taskCard_customer-like-icon__2T0_I {
  width: 1.171vw;
  height: 2.083vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }

.taskCard_add-task-button__2lwBy {
  width: 1.757vw;
  height: 3.125vh;
  border-radius: 50%; }

.taskCard_priorities-button-container__XO64y {
  position: absolute;
  right: 0vw;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center; }

.taskCard_set-priority-heading__34uy6 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_priority-buttons__2y2_Z {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.taskCard_each-priority__2_CeM {
  border-radius: 16px;
  box-sizing: border-box;
  height: 2.083vh;
  width: 2.489vw;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.taskCard_task-priority-container__1OKtm {
  position: absolute;
  right: 0vw;
  width: 3.7vw;
  height: 2.083vh;
  box-sizing: border-box;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px; }

.taskCard_task-priority-image__o-IsO {
  width: 0.732vw;
  height: 1.302vh; }

.taskCard_task-priority-letter__vk3eS {
  padding-left: 3px; }

.taskCard_blocker-tag-component__2ef5U {
  width: 97%;
  height: 2.344vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: auto; }

.taskCard_blocker-image__1IdnT {
  width: 0.878vw;
  height: 1.563vh; }

.taskCard_blocker-text__x8KPt {
  height: 100%;
  padding-left: 0.512vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_card-tag__27zAE {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  top: 0px;
  left: 0.366vw;
  padding: 0vh 0.3vw 0.1vh 0.1vw;
  align-items: center; }

.taskCard_card-tag-image__2uVWh {
  width: 1.171vw;
  display: flex;
  justify-content: center;
  align-items: center; }

.taskCard_card-tag-text__7OWTH {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75em; }

.taskCard_card-content__h1w1E {
  margin-top: 2vh; }

.taskCard_card-description__3dn3z {
  display: flex;
  line-height: 2.141vh;
  padding-top: 4px;
  padding-right: 0.586vw;
  font-weight: 350;
  font-size: 0.75em;
  margin-left: 0.366vw; }

.taskCard_card-bottom-container__3ZiH2 {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 3px;
  width: 95%;
  height: 2.083vh;
  margin-left: 4px;
  margin-bottom: 0.651vh;
  justify-content: space-between; }

.taskCard_priority-container-down-right__3Dgdo {
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.taskCard_priority-text__1ZbAO {
  display: flex;
  font-weight: 400; }

.taskCard_priority-container-up-right__2yj0w {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.taskCard_priority-container-down-left__6w-cY {
  position: absolute;
  right: 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  padding-right: 6px;
  box-sizing: border-box;
  border-radius: 2px; }

.taskCard_priority-image-container__2oKm2 {
  width: 1.171vw;
  display: flex;
  align-items: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskButton_main-component__2tLBk {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskButton_main-component__1KsLy {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.lowPriority_main-component__2lKcH {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mediumPriority_main-component__2XZ5W {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.highPriority_main-component__1GaCv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.blockerResolved_main-component__2Uapr {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.customerLike_main-component__29ay1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@media only screen and (min-width: 1280px) {
  .sprintReport_sprint-report-popup-content-container__sE34z {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .sprintReport_sprint-report-popup-content-container__sE34z {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .sprintReport_sprint-report-popup-content-container__sE34z {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReport_oa__1y42B {
  overflow: auto; }

.sprintReport_unfinshed-task__FL10A {
  width: 100%;
  height: 100%; }

.sprintReport_vertical-bar__2lFHK {
  height: 49.021vh;
  width: 1px;
  margin-top: 15px;
  box-sizing: border-box; }

.sprintReport_task-graph__daIId {
  width: 100%;
  margin-bottom: 1.5vh; }

.sprintReport_walkthrough-background__3zIeR {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

.sprintReport_sprint-report-container__3uVxl {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 87.5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Open Sans'; }

.sprintReport_sprint-report-content-container__3C1YX {
  width: 100%;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 90%; }

.sprintReport_sprint-report-popup-content-container__sE34z {
  padding: 0; }

.sprintReport_master__2QGTc {
  background-size: cover;
  overflow: auto;
  height: 89vh; }

.sprintReport_sprint-report-component__3oNbC {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0; }

.sprintReport_tabs__1ns-B {
  height: 10.417vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4.587vw 0 4.587vw; }

.sprintReport_tabs-popup__2WWfD {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7vh 3.8vw 0 4.587vw;
  box-sizing: border-box; }

.sprintReport_text__14DKx {
  width: 91%;
  font-family: 'Open Sans';
  margin: 1vh 4.587vw;
  padding: 1.7vh 2vw 1.3vh 2vw;
  box-sizing: border-box;
  border-radius: 4px; }
  .sprintReport_text__14DKx div:nth-child(2) {
    margin-top: 0vh; }

.sprintReport_info__2evXh {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
  width: 91%;
  margin: 15px 4.587vw; }

.sprintReport_metrics__2oV47 {
  box-sizing: border-box;
  padding-right: 15px; }

.sprintReport_met-name__J1ToN {
  width: 32.4vw;
  display: flex;
  height: 4.427vh;
  padding: 0.521vh 16px 0.521vh 16px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.sprintReport_heading__35_0m {
  width: 100%;
  display: flex;
  height: 4.427vh;
  padding: 4px 16px 4px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.sprintReport_game-completion-wrappper__NN1wS {
  width: 91.801vw;
  margin: auto;
  padding: 0 0.51vw 0 0.51vw;
  border-radius: 8px;
  position: relative; }

.sprintReport_footer-svg1__1vQx0 {
  position: absolute;
  z-index: -1;
  bottom: 7.5vh;
  width: 88.3vw;
  height: 3vh; }

.sprintReport_footer-svg2__aOGqR {
  position: absolute;
  z-index: -1;
  width: 56vw;
  height: 9vh;
  bottom: 1.5vh; }

.sprintReport_footer-svg3__shcO0 {
  position: absolute;
  z-index: 0;
  bottom: -1vh;
  right: 0; }

.sprintReport_customers-speak__1VJE0 {
  width: 90%; }

.sprintReport_task__jGWJW {
  width: 62%;
  box-sizing: border-box;
  padding-left: 15px; }

.sprintReport_task-row__1JSm2 {
  display: flex;
  flex-direction: column;
  width: 100%; }

.sprintReport_heading-text__1m-si {
  padding-right: 10px; }

.sprintReport_game-completed__2Fv4k {
  background: #FFFFFF;
  width: 88.3vw;
  height: 34.245vh;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 5vh; }

.sprintReport_img__3IunK {
  width: 21.449vw;
  height: 28.516vh;
  margin-left: 2vw; }

.sprintReport_content__1kCAA {
  margin-left: 1vw;
  width: 62vw; }

.sprintReport_title__28WYs {
  max-width: 100%; }

.sprintReport_ms__-tsQt {
  padding: 0.5vh 0px 4vh 0px;
  max-width: 100%; }

.sprintReport_download-button__3WCmw {
  background: linear-gradient(114.68deg, #FFFFFF 3.7%, #FFFFFF 100%);
  opacity: 0.9;
  border: 1px solid #232882;
  border-radius: 4px;
  width: 220px;
  height: 46px;
  color: #232882;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
  cursor: pointer; }

.sprintReport_walkthrough-tabs-container__1eY12 {
  padding: 22px 0; }

.sprintReport_flow__3QTMp {
  position: relative; }

.sprintReport_buttons-container__1-Hlp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.sprintReport_single-button-container__1fhSa {
  margin-right: 1vw;
  width: 20vw; }

.sprintReport_help-text-report__2I8rg {
  height: 20px;
  padding-top: 4px;
  width: 300px; }

.tabs_tab-container__2fLHv {
  display: flex;
  height: 5.208vh;
  border-bottom: 2px solid #DADADA;
  width: calc(100% - 11.468vw); }

.tabs_tab-container-popup__2A8v9 {
  display: flex;
  height: 5.208vh;
  border-bottom: 2px solid #989898;
  width: 100vw; }

.tabs_tab__2zb2w {
  height: 100%;
  width: 6.149vw;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  font-weight: 350;
  color: #FFFFFF; }

.tabs_selected__sHah6 {
  color: #EE7748;
  font-weight: 600;
  font-size: 1em;
  border-bottom: 2px solid #EE7748; }

.tabs_popup__1rUzO {
  color: black; }

.tabs_number__9p3Pv {
  padding-top: 1.3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.metric_big__1rDn5 {
  width: 32.3vw;
  height: 8.854vh;
  background: #FFFFFF;
  border-radius: 1px;
  margin: 1vh 0px 1vh 0px;
  position: relative;
  border: 1px solid #989898;
  box-sizing: border-box;
  border-radius: 4px; }

.metric_name-container__2og4i {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5vh 0px 0px 8px; }

.metric_name__3cnZD {
  padding-right: 5px; }

.metric_completion__25gK4 {
  line-height: 4vh;
  padding: 0px 0px 0px 8px; }

.metric_graph-box__2bS0t {
  padding: 5px 0px 0px 8px; }

.metric_graph__3FwGg {
  background: #232882;
  opacity: 0.1;
  border-radius: 2px;
  height: 4px;
  width: 380px; }

.metric_graph-overlay__2Uri8 {
  background: rgba(35, 40, 130, 0.9);
  border-radius: 2px;
  height: 4px;
  margin-top: -4px; }

.metric_small__3oNT6 {
  width: 10.168vw;
  height: 8.594vh;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #989898;
  position: relative;
  box-sizing: border-box; }

.metric_small-child__29BBh {
  width: 90%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.metric_mb__3Rgzk {
  height: 11.198vh; }
  .metric_mb__3Rgzk .metric_b-change__2kLjo {
    bottom: 1vh;
    right: 1.8vw; }

.metric_small-boxes-bottom__2JNmB {
  justify-content: space-between; }

.metric_small-boxes__2UBa2 {
  display: flex;
  margin-bottom: 0.8vh;
  width: 32.4vw;
  grid-gap: 0.91vw;
  gap: 0.91vw; }

.metric_big-box__3UCTa {
  display: flex; }

.metric_re-box-image__G-XYx {
  width: 1.757vw;
  height: 3.125vh; }

.metric_met-name__2PfiG {
  width: 32.4vw;
  display: flex;
  height: 4.427vh;
  padding: 4px 16px 4px 16px;
  border-radius: 4px;
  box-sizing: border-box;
    box-sizing-display: flex;
    box-sizing-align-items: center; }

.metric_arrow__hU29j {
  width: 0.878vw;
  height: 0.781vh;
  padding-top: 2px;
  padding-right: 3px; }

.metric_arrow-small__2pKZI {
  width: 0.878vw;
  height: 0.781vh;
  padding-top: 2px;
  padding-right: 3px; }

.metric_b-change-small__rBymw {
  display: flex;
  position: absolute;
  bottom: 14px;
  right: 8px;
  font-size: 0.625em;
  color: rgba(35, 40, 130, 0.7);
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_b-change__2kLjo {
  display: flex;
  position: absolute;
  bottom: 2.446vw;
  right: 2.294vh;
  font-size: 0.625em;
  color: rgba(35, 40, 130, 0.7);
  border-radius: 16px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_re-box__FpJ4W {
  background: white;
  border-radius: 4px;
  width: 15.596vw;
  height: 8.594vh;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 0.5vh;
  box-sizing: border-box;
  border: 1px solid #989898; }

.metric_re-completion__u6TTf {
  padding: 0px 0px 0px 1.025vw;
  margin-top: -0.5vh; }

.metric_re-name-container__1vMUv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0px 0px 1.025vw; }

.metric_re-name__3PQ4n {
  padding-right: 5px; }

.metric_value-red__1SRmv {
  color: #D11021;
  box-sizing: border-box; }

.metric_value-green__3NnZw {
  color: #0B7F3D;
  box-sizing: border-box; }

.metric_sim-mvp-container__JYLGV {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  height: 100%; }

.metric_sim-mvp-content__1n6df {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  width: 100%;
  height: 100%; }

.metric_sim-mvp-details__11MLU {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.metric_sim-mvp-top__3UALU {
  width: 100%;
  display: flex; }

.metric_sim-mvp-text__qEs1o {
  padding-right: 5px; }

.metric_sim-mvp-value__3flhC {
  margin-right: 6px; }

.metric_sim-mvp-bottom__2JDJd {
  width: 100%; }

.metric_sim-mvp-progress-bar-container__1iLQN {
  width: 100%;
  border-radius: 4px; }

.metric_sim-mvp-progress-bar__1R1QJ {
  height: 7px; }

.metric_sim-mvp-progress-bar__1R1QJ {
  height: 7px;
  border-radius: 3px;
  display: flex; }

.metric_sim-mvp-arrow-container__3uNV2 {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.upArrow_main-component__3fMRm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.downArrowReport_main-component__3FUmo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.calendar_main-component__3kU1R {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_story__hLSn- {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 30%;
  height: 18.229vh;
  padding: 1vh 0.4vw;
  box-sizing: border-box;
  position: relative;
  margin-top: 1vh;
  margin-left: 0.8vw;
  border: 2px solid #DADADA; }

.stories_card-name__3BHlK {
  font-weight: 600;
  font-size: 0.75em;
  padding: 0px 0px 5px 0px;
  margin-top: 2vh; }

.stories_card-description__2nF9l {
  font-weight: 600;
  font-size: 0.75em;
  padding: 0px 0px 10px 0px; }

.stories_info__3-If_ {
  font-size: 12px;
  color: rgba(3, 26, 32, 0.5);
  margin-top: 3px; }

.stories_bottom__2Pclu {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 3px;
  width: 95%; }

.stories_priority__2n_1s {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 33px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_priority_arabic__1Y59A {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 60px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_priority-image__1GXYw {
  width: 10px;
  height: 10px;
  margin-right: 3px; }

.stories_priority-letter__3v94a {
  color: #42BAFF;
  font-weight: bold;
  font-size: 10px; }

.stories_wrap__1JJYS {
  overflow: auto;
  width: 100%;
  background-color: #474747B2;
  height: 29.036vh;
  margin-top: 0.5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px; }

.stories_wrap-popup__1fh-j {
  overflow: auto;
  width: 100%;
  height: 29.036vh;
  margin-top: 0.5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px;
  border: 1px solid #989898; }

.stories_customers-speak__3BX9U {
  width: 70%; }

.stories_priority-container__2Jh4u {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0px 0.293vw;
  margin-bottom: 5px;
  box-sizing: border-box;
  border-radius: 2px; }

.stories_priority-text__3Dgkf {
  display: flex; }

.stories_card-tag__2noXD {
  padding: 1px 0.3vw 0.3vh 0.1vw;
  grid-gap: 0.2vw;
  gap: 0.2vw; }

.stories_card-tag__2noXD {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0.45vw;
    left-border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.stories_card-tag-image__J1jA5 {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_card-tag-text__2XZjn {
  display: flex;
  align-items: center;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1em; }

.stories_priority-image-container__1YM5l {
  width: 1.171vw;
  display: flex;
  align-items: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.cust_comment__A0Jij {
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 25px;
  color: rgba(3, 26, 32, 0.7);
  min-height: 34px; }

.cust_heading__2ancn {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(35, 40, 130, 0.6); }

.cust_top__21Oq7 {
  display: flex;
  margin-bottom: 5px; }

.cust_avatar-wrap__hH2qP {
  margin-left: 28px;
  margin-top: -6px; }
  .cust_avatar-wrap__hH2qP img {
    height: 35px;
    width: 35px; }

.cust_comment-container__2n7qn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(35, 40, 120, 0.2);
  padding: 5px 0; }

.cust_comment-image__3VQE6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin: 2px 10px 0 10px;
  position: relative; }

.cust_normal-chat-image__1jtHe {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: -12px; }

.completion_legend__3TzMm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-sizing: border-box; }

.completion_wrap__3Gz0a {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 2vh 0.65vw 1vh 0.65vw;
  height: 10.026vh;
  margin-top: 1vh;
  box-sizing: border-box;
  border-radius: 4px; }

.completion_sim-mvp-progress-bar-container__Kj2Kh {
  width: 100%;
  height: 3vh;
  text-align: right;
  position: relative;
  padding-right: 1vw;
  padding-bottom: 1vh;
  box-sizing: border-box; }

.completion_sim-mvp-progress-bar__cTDJz {
  height: 3vh;
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 1vw;
  box-sizing: border-box;
  padding-left: 0.3vw;
  padding-bottom: 1vh; }

.completion_graph-progress-bar__1K1JX {
  width: 100%; }

.completion_graph__3Rhv4 {
  width: 100%;
  box-sizing: border-box; }

.completion_square__2mzCJ {
  width: 12px;
  height: 12px;
  margin-right: 4px; }

.completion_leg-wrap__3g-GT {
  padding-bottom: 7px;
  display: flex;
  margin-left: 12px; }

.completion_green__3g5fO {
  background: #B5F000; }

.completion_white__3txf2 {
  background: #FFFFFF; }

.completion_grey__3bZ5U {
  background: #474747B2; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.circularProgressBar_bar-container__2-Y7m {
  width: 100%;
  height: 100%; }

.circularProgressBar_progressbar-container__10aTq {
  position: relative; }

.circularProgressBar_progress-number__2rJYV {
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 35%;
  left: 20%;
  padding: 0;
  margin: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.gameComplete_main-component__2DBMv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DADADA; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintFooter_sprint-footer-container__149A6 {
  width: 100%;
  height: 5.8vh;
  box-sizing: border-box;
  position: relative; }

.sprintFooter_sprint-footer-background__1z0Pb {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; }

.sprintFooter_sprint-footer-component-content__2W12_ {
  width: 100%;
  height: 5.8vh;
  z-index: 2;
  position: absolute;
  bottom: -1.5vh;
  right: 4.6vw; }

.sprintFooter_sprint-footer-component__JOZWI {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.history_history-container__2mQKv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0px;
  height: 50%; }

.history_history-content__3Kiwo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.2vw;
  cursor: pointer;
  height: 100%; }

.history_history-name__3MhCM {
  padding-left: 5px;
  cursor: pointer; }

.history_history-image__3iB48 {
  width: 24px;
  height: 24px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.historySvg_main-component__3AKaf {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sendEmailReport_email-report-container__3WRh0 {
  width: 100%;
  margin-right: 20px;
  display: flex;
  height: 5.208vh; }

.sendEmailReport_email-heading__M7Xs0 {
  width: 100%;
  padding-bottom: 4px; }

.sendEmailReport_email-input-container__3NUL4 {
  width: 23vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.sendEmailReport_email-input__2KRDR {
  width: calc(100% - 25px);
  height: 100%; }

.sendEmailReport_email-send__21vLq {
  margin-left: 1vw; }

.sendEmailReport_email-input-element__2GLHp {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white; }

.sendEmailReport_email-input-element__2GLHp:focus {
  outline: none; }

.sendEmailReport_email-status-bar__2ClBd {
  width: 100%;
  padding-top: 4px;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.emailSend_main-component__23IgF {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.gameIncomplete_main-component__eaR8F {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DADADA; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReportPopupContainer_sprint-report-popup-container__1oE4s {
  width: 91.801vw;
  height: 73.047vh;
  border-radius: 8px;
  overflow: hidden;
  position: relative; }

.sprintReportPopupContainer_sprint-report-popup-header__2dxyB {
  width: 100%;
  height: 67vh;
  overflow: auto; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReportPopupHeader_header-container__18saW {
  width: 100%;
  min-height: 5.208vh;
  position: relative;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  overflow: hidden; }

.sprintReportPopupHeader_header-background-container__3_xPb {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sprintReportPopupHeader_header-content-container__IDpQe {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintReportPopupHeader_header-details-container__1mQ4X {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box; }

.sprintReportPopupHeader_story-name__La6SM {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintReportPopupHeader_close-container__edns7 {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-end;
  margin-right: 1vw; }

.sprintReportPopupHeader_close-icon__3VpX0 {
  width: 1.757vw;
  height: 3.125vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.productLaunchPopup_main-component__fVPLd {
  width: 49.634vw;
  min-height: 32.422vh;
  border-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container__3ZncI {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container-content__1hDzn {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.productLaunchPopup_popup-header-container-background__Ao4FF {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.productLaunchPopup_popup-details-container__neTdQ {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_popup-buttons-container__1MZKP {
  width: 100%;
  height: 13.281vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_button-container__2PUf2 {
  padding: 0 10px; }

.productLaunchPopup_header-image__cj3qI {
  padding: 1.5vh 1vw 1vh 1vw; }

.productLaunchPopup_header-text__2liHo {
  text-align: center;
  width: 100%;
  box-sizing: border-box; }

.productLaunchPopup_header-desc__suMC0 {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.productLaunchPopup_popup-desc-container__2aYCV {
  box-sizing: border-box;
  padding: 1vh 1vw 0 1vw;
  text-align: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.leaderboardPopup_leaderboard-popup-container__21Glb {
  width: 67.2vw;
  height: 68.7vh;
  box-sizing: border-box; }

.leaderboardPopup_header-container__3vYXl {
  width: 100%;
  height: 8vh;
  z-index: 2;
  position: relative; }

.leaderboardPopup_header-background-container__1XQAx {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.leaderboardPopup_title__R-wF_ {
  padding: 2.6vh 0px 0px 1.4vw;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0; }

.leaderboardPopup_close-icon__1n0ml {
  position: absolute;
  top: 1.5vh;
  right: 1vw;
  height: 5vh;
  z-index: 1;
  margin-top: 0.4vh; }

.leaderboardPopup_refresh-container__9p4xW {
  position: absolute;
  top: 1.7vh;
  right: 7vw;
  z-index: 1;
  margin-right: 0.5vw; }

.leaderboardPopup_content__2pgl4 {
  width: 100%;
  height: calc(100% - 116px);
  position: relative;
  top: 0;
  left: 0; }

.leaderboardPopup_table-header-container__1W_hC {
  width: 100%;
  height: 4vh;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 2vh 0px 0px 0px; }

.leaderboardPopup_position__mTEza {
  width: 8vw;
  box-sizing: border-box; }

.leaderboardPopup_user-container__2XtCo {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  padding-right: 10px; }

.leaderboardPopup_username-heading__32y96 {
  width: 39vw;
  box-sizing: border-box;
  padding-left: 1vw; }

.leaderboardPopup_username__1VQxe {
  width: 41vw;
  box-sizing: border-box;
  padding-left: 1vw; }

.leaderboardPopup_days-remaining__3Dmk0 {
  width: 10vw;
  box-sizing: border-box; }

.leaderboardPopup_mvp__5fCdf {
  box-sizing: border-box; }

.leaderboardPopup_position-header__f3l5- {
  padding-left: 2.4vw; }

.leaderboardPopup_position-body__2h1uw {
  display: flex;
  align-items: center;
  justify-content: center; }

.leaderboardPopup_mvp-body__gYHTO {
  text-align: right; }

.leaderboardPopup_table-body-container__32hPc {
  height: 49vh;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1vh; }

.leaderboardPopup_table-row__U8QU6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 4vh;
  padding-top: 2vh; }

.leaderboardPopup_seperation-line__2-wcQ {
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 0 16px;
  height: 1px; }

.leaderboardPopup_table-container__36029 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

.leaderboardPopup_button-wrapper__2jqMW {
  display: flex;
  align-items: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.closedButton_button-style__1r3Oy {
  box-sizing: border-box; }

.closedButton_button-style__1r3Oy {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.knowYourTeam_main-component__9lunw {
  width: 54.539vw;
  position: relative; }

.knowYourTeam_header-container__1KxKj {
  padding: 0.5vw 0.5vw 0.3vw 2vw;
  display: flex;
  justify-content: space-between;
  margin-top: 8vh;
  align-items: center;
  height: 6.771vh; }

.knowYourTeam_close-icon__3Ha-Y {
  position: relative;
  z-index: 1;
  margin-right: 4vw;
  height: 4.167vh; }

.knowYourTeam_popup-body__17Et8 {
  padding: 0.5vw 0.5vw 0.3vw 2vw;
  width: 46.925vw;
  height: 75vh;
  overflow-x: none; }

.knowYourTeam_footer-svg__1HZwq {
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: -0.5vh; }

.knowYourTeam_team-members-container__29754 {
  display: flex;
  justify-container: start;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  margin-top: 2vh; }

.knowYourTeam_card-style__MqWAX {
  margin: 2px 2px;
  width: 23.133vw;
  text-align: center; }

.knowYourTeam_card-header__d-Ov7 {
  display: flex;
  justify-content: space-around; }

.knowYourTeam_tm-member-details__3G8Gv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5vh; }

.knowYourTeam_img-wrapper__6PXC4 {
  height: 16.2vh;
  width: 8.785vw; }

.knowYourTeam_tm-member-text-details__1Gh7P {
  height: 7.943vh;
  display: flex;
  margin-top: 1vh; }

.knowYourTeam_name-details__2RUuJ {
  font-weight: bold; }

.knowYourTeam_tm-morale-stats__3JQ48 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 40px; }

.knowYourTeam_tm-skill-stats__1v3s2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 45px; }

.knowYourTeam_stat-value__3129c {
  padding: 1.5px 12px;
  border-radius: 10px; }

.knowYourTeam_skill-stats-header__13F74 {
  display: flex;
  align-items: center; }

.knowYourTeam_skill-icon__3AzuK {
  width: 11px;
  height: 11px;
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.knowYourCustomers_main-component__2gd4i {
  width: 814px;
  height: calc(100% - 38px);
  position: relative; }

.knowYourCustomers_header-container__15hcL {
  border-radius: 0;
  padding: 24px 30px 16px 30px;
  display: flex;
  justify-content: space-between; }

.knowYourCustomers_close-icon__3c_WC {
  position: absolute;
  right: 2vw;
  z-index: 1;
  top: 2.3vh; }

.knowYourCustomers_popup-body__2nwhX {
  padding: 12px 24px;
  height: calc(100% - 240px);
  overflow: auto; }

.knowYourCustomers_card-style__1wQhF {
  padding: 20px;
  margin: 16px 0; }

.knowYourCustomers_card-header__3zd20 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px; }

.knowYourCustomers_tm-member-details__2ZACK {
  display: flex; }

.knowYourCustomers_name-details__3rVb- {
  margin-left: 10px; }

.knowYourCustomers_footer-container__3UjC1 {
  display: flex;
  align-items: center;
  bottom: 10px;
  padding: 5px 15px;
  box-sizing: border-box;
  height: 170px; }

.knowYourCustomers_footer-image__1cLMc {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.knowYourCustomers_cpo-details__202nD {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionLoader_loader-container__XUeSJ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.actionLoader_svg-container__2TV_Z {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: actionLoader_loadingMove__1VGWe 3s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.actionLoader_svg-wrapper__3BQ6V {
  width: 100%;
  height: 100%; }

@keyframes actionLoader_loadingMove__1VGWe {
  0%, 100% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(180deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.eventLoader_loader-container__3sklq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.eventLoader_svg-container__OENcb {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: eventLoader_loadingMove__uvUXh 1.5s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.eventLoader_svg-wrapper__BRW7U {
  width: 100%;
  height: 100%; }

@keyframes eventLoader_loadingMove__uvUXh {
  from {
    transform: rotate(0deg); }
  10% {
    transform: rotate(15deg); }
  20% {
    transform: rotate(-15deg); }
  30% {
    transform: rotate(0deg); }
  to {
    transform: rotate(0deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.introPopup_intro-popup-container__5RzLV {
  width: 88.287vw;
  height: 82.552vh;
  box-sizing: border-box; }

.introPopup_intro-popup-container2__3mOmg {
  width: 88.287vw;
  height: 42.708vh;
  box-sizing: border-box; }

.introPopup_header-container__weCO4 {
  width: 100%;
  height: 7.292vh;
  z-index: 2;
  position: relative;
  top: 0;
  left: 0; }

.introPopup_header-background-container__1KEWz {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

.introPopup_header-content__178bX {
  position: relative;
  padding-top: 1.5vh;
  z-index: 1; }

.introPopup_title__2wPPQ {
  position: absolute;
  left: 2vw;
  z-index: 1; }

.introPopup_close-icon__3gWQU {
  position: absolute;
  right: 1vw;
  top: 1.6vh;
  z-index: 1; }

.introPopup_content__3hOuC {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column; }

.introPopup_intro-content-container__2mNS7 {
  z-index: 3;
  overflow-y: auto;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: row; }

.introPopup_button-container__vruMg {
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: absolute;
  bottom: 6vh;
  right: 1vw; }

.introPopup_button-container2__2LqsD {
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: absolute;
  bottom: 3.6vh;
  right: 1vw; }

.introPopup_content-background-1__2Q5BA {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0; }

.introPopup_content-background-2__13UPE {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }

.introPopup_slide-intro__1jMwj {
  padding-top: 2vh;
  margin: 0.1vh 1vw 1vw 2vw; }

.introPopup_intro-content-container2__xNWR2 {
  display: flex;
  position: absolute;
  top: 25vh;
  right: 0.4vw;
  z-index: 1; }

.introPopup_transcript-container__v2t9J {
  padding: 15px 50px;
  box-sizing: border-box;
  width: 100%; }

.introPopup_transcript-show__2gvI8 {
  padding-top: 5px;
  display: block;
  width: 100%; }

.introPopup_transcript-hidden__5uH8w {
  padding-top: 5px;
  display: none; }

.introPopup_transcript__1qUwg {
  cursor: pointer;
  display: flex; }

.introPopup_video-container__1JgWG {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: black;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden; }

.introPopup_video__3ArMr {
  background-color: black; }

.introPopup_icon__ldxzF {
  height: 8px;
  width: 12px;
  padding-top: 10px; }

.introPopup_playing-conditions-container__1kqW8 {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  top: 0;
  left: 0; }

.introPopup_sprint-plan-header-container__2Qg9W {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2vw;
  margin-right: 7vw; }

.introPopup_sprint-story-container__13Yhr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 2vw;
  margin-right: 3vw; }

.introPopup_sprint-stories-container__3TFMF {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-gap: 7vh;
  gap: 7vh;
  overflow-y: scroll;
  margin-top: 0.5vh;
  padding-left: 0.13vw;
  padding-right: 0.2vw; }

.introPopup_sprint-plan-sprint-story-container__3Bip_ {
  width: -webkit-fill-available;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1.2vw;
  margin-top: 2vh;
  scroll-behavior: 'smooth';
  overflow-x: hidden;
  padding-right: 0.2vw; }

.introPopup_header__V68jS {
  box-sizing: border-box;
  padding: 2vh 3vw;
  width: 91vw;
  display: flex;
  flex-direction: column;
  align-items: center; }

.introPopup_header-title__2qkgG {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; }

.introPopup_header-desc__2LdsE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5vh; }

.introPopup_sprint-number__30Apr {
  color: #fff;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2vh;
  font-size: 0.875em;
  margin-top: 1.223vh;
  margin-left: 0.5vw;
  padding: 0.5vh 0.5vw; }

.introPopup_sprint-story__K-NgG {
  height: 10vh;
  margin-bottom: 1vh; }

.introPopup_card-container__2DvR_ {
  width: 6vw;
    width-height: 6vh; }

.introPopup_sprint-button-container__B3qOq {
  width: 90vw;
  margin-left: 5vw;
  margin-right: 3vw;
  margin-top: -3vh; }

.introPopup_card-container__2DvR_ {
  width: 15.596vw;
  height: 16.667vh;
  box-sizing: border-box;
  margin: 0 7px 10px 5px;
  position: relative;
  margin-right: 1vw; }

.introPopup_card-tag__3kt5E {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 0.5vw;
  align-items: center;
  padding: 0vh 0.3vw 0.1vh 0.1vw; }

.introPopup_card-tag-image__3mlRF {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.introPopup_card-tag-text__JTQ4p {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px; }

.introPopup_card-header__2mpTL {
  line-height: 2.446vh;
  width: 14.297vw;
  font-weight: 600;
  font-size: 12px; }

.introPopup_card-content__23iJL {
  width: 14.373vw;
  height: 7.645vh;
  margin-top: 3.211vh;
  margin-left: 0.612vw; }

.introPopup_card-description__15wJ2 {
  display: flex;
  width: 14.297vw;
  line-height: 2.141vh;
  margin-top: 3px;
  padding-top: 4px;
  font-weight: 350;
  font-size: 10px; }

.introPopup_card-bottom-container__2Cphl {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 3px;
  width: 95%;
  margin-left: 4px;
  justify-content: space-between; }

.introPopup_story-point-container__1RUIN {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.introPopup_story-point-text__2wrkF {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.introPopup_story-point-number__2KMPO {
  font-weight: 600;
  font-size: 10px; }

.introPopup_story-point-icon__2rpcH {
  display: flex;
  width: 8px;
  height: 8px; }

.introPopup_priority-container__1vA8z {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0px 0.293vw;
  border-radius: 2px; }

.introPopup_priority-text__3Yd2I {
  display: flex;
  font-weight: 400;
  font-size: 10px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintWisePlan_intro-content-container__1tJB3 {
  padding-top: 25px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box; }

.sprintWisePlan_sprint-plan-header-container__27bcZ {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.sprintWisePlan_sprint-story-container__1XE_d {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 88.287vw;
  margin: auto; }

.sprintWisePlan_sprint-stories-container__28xa9 {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-gap: 7vh;
  gap: 7vh;
  overflow-y: scroll;
  margin-top: 0.5vh;
  padding-left: 0.13vw;
  padding-right: 0.2vw; }

.sprintWisePlan_sprint-plan-sprint-story-container__38wh1 {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2vh;
  scroll-behavior: 'smooth';
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding-right: 0.2vw; }

.sprintWisePlan_header__19nZe {
  box-sizing: border-box;
  padding: 2vh 1.5vw;
  width: 88.287vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.sprintWisePlan_header-title__3gG4J {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; }

.sprintWisePlan_header-desc__6yfpI {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5vh; }

.sprintWisePlan_sprint-number__1z7fb {
  color: #fff;
  height: 3.125vh;
  width: 4.8vw;
  line-height: 3.058vh;
  font-size: 14px;
  margin-top: 1.223vh;
  margin-left: 0.5vw;
  padding: 2px 0px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWisePlan_sprint-story__3u426 {
  height: 10vh;
  margin-bottom: 1vh; }

.sprintWisePlan_card-container__33ir4 {
  width: 6vw;
    width-height: 6vh; }

.sprintWisePlan_sprint-button-container__1Vr5P {
  width: 88vw;
  margin: auto;
  margin-top: -3vh; }

.sprintWisePlan_card-container__33ir4 {
  width: 15.596vw;
  height: 16.667vh;
  box-sizing: border-box;
  margin: 0 7px 10px 5px;
  position: relative;
  margin-right: 0.2vw; }

.sprintWisePlan_card-tag__3i7Oj {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 0.5vw;
  align-items: center;
  padding: 0vh 0.3vw 0.1vh 0.1vw;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.sprintWisePlan_card-tag-image__1a_Jg {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWisePlan_card-tag-text__25x-h {
  display: flex;
  font-weight: 600;
  font-size: 0.75em;
  align-items: center; }

.sprintWisePlan_card-header__3_NtU {
  line-height: 2.446vh;
  width: 14.297vw;
  font-weight: 600;
  font-size: 0.75em; }

.sprintWisePlan_card-content__3Wjfh {
  width: 14.373vw;
  height: 7.645vh;
  margin-top: 3.211vh;
  margin-left: 0.4vw; }

.sprintWisePlan_card-description__-IWYM {
  display: flex;
  width: 14.297vw;
  line-height: 2.141vh;
  margin-top: 3px;
  padding-top: 4px;
  font-weight: 500;
  font-size: 0.625em; }

.sprintWisePlan_card-bottom-container__1Zlt3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 3px;
  width: 95%;
  margin-left: 4px;
  justify-content: space-between; }

.sprintWisePlan_story-point-container__3hm2e {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.sprintWisePlan_story-point-text__1QNk_ {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.sprintWisePlan_story-point-number__1_9qT {
  font-weight: 600;
  font-size: 0.625em; }

.sprintWisePlan_story-point-icon__2v-k6 {
  display: flex; }

.sprintWisePlan_priority-container__27pxI {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.sprintWisePlan_priority-text__2_YMX {
  display: flex;
  font-weight: 400;
  font-size: 0.625em; }

.sprintWisePlan_priority-image-container__dCQJC {
  width: 1.171vw;
  display: flex;
  align-items: center; }

.sprintWisePlan_priority-image__3-ihy {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.history_b-msg__15Qim {
  padding: 0 15px;
  font-size: 0.875em;
  color: rgba(3, 26, 32, 0.6);
  margin-top: 8px; }

.history_container__38_jj {
  height: 89.3vh;
  width: 53vw;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
  margin-top: 8vh; }

.history_header__2gSNn {
  height: 6.771vh;
  width: 100%;
  background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
  display: flex;
  justify-content: space-between;
  align-items: center; }

.history_header-content__1Rw2- {
  font-size: 1.75em;
  padding-top: 1vh;
  font-weight: 600;
  color: white;
  padding-left: 2vw; }

.history_list__36Bz9 {
  margin-left: 5.857vw;
  width: 38vw;
  z-index: 1; }

.history_day__2u62g {
  position: relative;
  min-height: 13.021vh;
  margin-left: 1vw;
  border-left: 2px solid #989898; }

.history_number__1S-ZL {
  position: absolute;
  left: -5.124vw;
  font-size: 0.875em;
  font-weight: 600;
  color: #333333; }

.history_box__30pfJ {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 13vh);
  box-sizing: border-box;
  opacity: 0.95;
  z-index: 0; }

.history_footer-svg__3JR_J {
  display: flex;
  position: fix;
  margin-top: -10vh;
  z-index: 0; }

.history_close__4KIGR {
  position: absolute;
    position-width: 1.83vw;
    position-height: 3.255vh;
  top: 9.7vh;
  right: 5vw; }

.history_line__2Q5-m {
  width: 13.021vw; }

.history_circle__fTBb6 {
  background: #FFFFFF;
  border: 1px solid #972082;
  box-sizing: border-box;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  position: absolute;
  left: -9px; }

.history_action__2K7Br {
  width: 48.829vw;
  border-top: 2px solid #fbbd0e;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_event__1x1WG {
  width: 48.829vw;
  border-top: 2px solid #972082;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  margin-left: 15px;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker__1KTzD {
  width: 17.57vw;
  border-top: 2px solid #d11021;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker-msg__3Hkxf {
  width: 411px;
  border-top: 2px solid #d11021;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  padding: 10px;
  margin-bottom: 15px; }

.history_header-tag__3-GjS {
  display: flex;
  align-items: center; }

.history_spacer__2kw7k {
  padding: 2px 0px;
  display: flex; }

.history_a-hash__2h-1q {
  font-size: 0.75em;
  color: #fbbd0e;
  font-weight: bold; }

.history_sprint-day-tag__21mce {
  padding: 2px 7px;
  background: rgba(2, 26, 32, 0.07);
  color: rgba(2, 26, 32, 0.7);
  font-size: 0.75em;
  margin-left: 10px;
  border-radius: 8px; }

.history_e-hash__37sW8 {
  font-size: 0.75em;
  color: #972082;
  font-weight: bold; }

.history_b-hash__2aRU- {
  font-size: 0.75em;
  color: #d11021;
  font-weight: bold; }

.history_a-name__3IoCz {
  font-weight: 600;
  font-size: 0.875em;
  color: black; }

.history_action-option-name__1vT2A {
  font-weight: 600;
  font-size: 0.875em;
  color: #333333;
  margin-top: 2px; }

.history_action-option__2HNVn {
  height: 2.604vh;
  font-weight: 350;
  line-height: 2.604vh;
  font-size: 0.875em;
  margin-top: 2px;
  color: #333333; }

.history_e-msg__2Gf4c {
  font-size: 0.875em;
  color: #333333;
  margin-top: 1.042vh; }

.history_met-box__1qbdl {
  display: flex;
  margin-top: 1.302vh; }

.history_met-box-eve__1x20x {
  margin-top: 1.953vh; }

.history_metric__1R4DW {
  height: 2.804vh;
  border-radius: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  line-height: 2.083vh;
  margin: 1vh;
  display: flex;
  align-items: center; }

.history_arrow-cont__2givB {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh; }

.history_metric-diff__rfog_ {
  display: flex;
  font-size: 0.75em;
  font-weight: 700;
  justify-content: center; }

.history_met-value__3moL2 {
  display: flex;
  font-size: 1em;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  width: 1.977vw;
  margin-left: 0.2vw; }

.history_met-container__1pRkA {
  margin-right: 0.732vw;
  display: flex;
  height: 5.208vh;
  border: 1px solid #989898;
  border-radius: 4px; }

.history_met-icon__24X5W {
  display: flex;
  width: 1.757vw;
  margin-left: 0.22vw;
  margin-right: 0.146vw; }

.history_met-name__3AVPJ {
  font-size: 0.725em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  color: black;
  text-transform: capitalize;
  margin-left: 0.293vw;
  margin-right: 0.19vw; }

.history_arrow__1cFjh {
  width: 1.171vw; }

.history_filter__23SOO {
  height: 6vh;
  display: flex;
  margin-left: 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: center; }
  .history_filter__23SOO div:first-child {
    font-size: 1em;
    color: #031A20;
    display: flex;
    justify-content: center; }

.history_filter-by__3a9ct {
  margin-right: 0.586vw;
  display: flex;
  justify-content: center; }

.history_line-separator__2fGQ6 {
  width: 96.4%;
  height: 1px;
  background-color: #989898;
  opacity: 1;
  margin-left: auto; }

.history_filter-box__1m7TQ {
  display: block;
  cursor: pointer;
  border: 1px solid #ee7748;
  padding: 4px 10px;
  border-radius: 4px;
  margin-left: 0.4vw;
  box-sizing: border-box;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center; }

.history_filter-name__2wIq3 {
  margin-right: 0px; }

.history_slected__3XGob {
  background-color: #ee7748; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.areaMapPopup_leaderboard-popup-container__2YGta {
  width: 67vw;
  height: 77vh;
  box-sizing: border-box; }

.areaMapPopup_header-container__1lfQc {
  width: 100%;
  height: 6.5vh;
  z-index: 2;
  position: relative; }

.areaMapPopup_header-background-container__2-Aoc {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.areaMapPopup_title__2z0Hw {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 1.2vw;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  text-size: 30px; }

.areaMapPopup_close-icon__2rItX {
  position: absolute;
  top: 1.2vh;
  right: 1vw;
  z-index: 1; }

.areaMapPopup_refresh-container__1Kzpl {
  position: absolute;
  top: 0.7vh;
  right: 8vw;
  z-index: 1; }

.areaMapPopup_table-header-container__9BCDe {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 10px; }

.areaMapPopup_position__uGVI6 {
  min-width: 100px;
  box-sizing: border-box; }

.areaMapPopup_username__G1dmX {
  min-width: 520px;
  box-sizing: border-box; }

.areaMapPopup_days-remaining__1FpAI {
  min-width: 145px;
  box-sizing: border-box; }

.areaMapPopup_mvp__2dxSR {
  min-width: 75px;
  box-sizing: border-box; }

.areaMapPopup_position-header__1anQV {
  padding-left: 22px; }

.areaMapPopup_position-body__YAn78 {
  display: flex;
  align-items: center;
  justify-content: center; }

.areaMapPopup_mvp-body__3rPRn {
  text-align: right; }

.areaMapPopup_table-body-container__3bET6 {
  height: calc(100% - 25px);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden; }

.areaMapPopup_table-row__3Mq_j {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0; }

.areaMapPopup_seperation-line__30AtG {
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 0 16px;
  height: 1px; }

.areaMapPopup_table-container__2vOAV {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

.areaMapPopup_map-container__1flLB {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 97%; }

.areaMapPopup_map-image__1fSGn {
  height: 95%;
  width: 97%;
  overflow: auto; }

.areaMapPopup_area-name__39BaG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  grid-gap: 0.5vw;
  gap: 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

