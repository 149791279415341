@import '~styles/mixins.sass';

.footer-container {
	width: 100%;
	height: 2.995vh;
	position: absolute;
	bottom: 0;
	z-index: 5;
	@include display-flex-center-mixin; }

.sim-name {
	padding: 0 4px; }

.sim-tag {
	padding: 0 4px; }

.knolskape-logo {
	padding: 0 2px 0 4px;
	@include display-flex-center-mixin;
	cursor: pointer; }

.footer-bar {
	@include display-flex-center-mixin; }
