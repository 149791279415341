@import '~styles/mixins.sass';

.tab-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.3vw;
	height: 4.948vh;
	width: 11vw;
	box-sizing: border-box;
	border-radius: 4px; }


.symbol-container {
	display: flex;
	align-items: center;
	width: 1.171vw;
	height: 2.283vh; }

.tab-name {
	margin-bottom: 2px; }
