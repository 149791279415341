@import '~styles/mixins.sass';

.email-report-container {
	width: 100%;
	margin-right: 20px;
	display: flex;
	height: 5.208vh; }

.email-heading {
	width: 100%;
	padding-bottom: 4px; }

.email-input-container {
	width: 23vw;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box; }

.email-input {
	width: calc(100% - 25px);
	height: 100%; }

.email-send {
	margin-left: 1vw; }

.email-input-element {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	color: white; }

.email-input-element:focus {
	outline: none; }

.email-status-bar {
	width: 100%;
	padding-top: 4px;
	box-sizing: border-box; }
