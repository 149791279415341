@import '~styles/mixins.sass';

.container {
	@include display-flex-start;
	min-width: 17.57vw; }

.number {
	@include display-flex-center-mixin;
	border-radius: 50%;
	width: 1.724vw;
	height: 1.724vw;
	padding-bottom: 0.4vh;
	box-sizing: border-box; }

.check-container {
	width: 1.724vw;
	height: 1.724vw; }

.title {
	margin-left: 0.5vw; }
