// Open Sans Font

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/opensans/OpenSans-ExtraBold.ttf') format("truetype");
	font-weight: 800;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/opensans/OpenSans-Bold.ttf') format("truetype"), url('fonts/opensans/OpenSans-Bold.woff') format("woff"), url('fonts/opensans/OpenSans-Bold.eot');
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/opensans/OpenSans-Semibold.ttf') format("truetype"), url('fonts/opensans/OpenSans-Semibold.woff') format("woff"), url('fonts/opensans/OpenSans-Semibold.eot');
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/opensans/OpenSans-Regular.ttf') format("truetype"), url('fonts/opensans/OpenSans-Regular.woff') format("woff"), url('fonts/opensans/OpenSans-Regular.eot');
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('fonts/opensans/OpenSans-Light.ttf') format("truetype"), url('fonts/opensans/OpenSans-Light.woff') format("woff"), url('fonts/opensans/OpenSans-Light.eot');
	font-weight: 300;
	font-style: normal; }


// LibreBaskerville

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/libreBaskerville/LibreBaskerville-Regular.ttf) format("truetype"), url('fonts/opensans/OpenSans-Regular.woff') format("woff"), url('fonts/opensans/OpenSans-Regular.eot'); }


@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(fonts/libreBaskerville/LibreBaskerville-Bold.ttf) format("truetype"), url('fonts/opensans/OpenSans-Bold.woff') format("woff"), url('fonts/opensans/OpenSans-Bold.eot'); }


//Digital

@font-face {
  font-family: 'Digital';
  src: url('fonts/digital/digital-7.ttf') format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url('https://use.typekit.net/unk8txf.css'); }

@font-face {
  font-family: 'freight-display-pro';
  src: url('https://use.typekit.net/unk8txf.css'); }

@font-face {
  font-family: 'freight-text-pro';
  src: url('https://use.typekit.net/unk8txf.css'); }
