@import '~styles/mixins.sass';

.leaderboard-container {
	height: 5.199vh;
	width: 35%;
	padding: 0.5vh 0.5vw;
	box-sizing: border-box;
	border-radius: 0.2vw;
	@include display-flex-center-mixin;
	cursor: pointer;
	justify-content: space-evenly;
	margin-right: 0.6vw;
	margin-left: 0.5vw; }

.leaderboard-image-container {
	width: 1.318vw;
	height: 2.344vh;
	@include display-flex-center-mixin; }

.leaderboard-image {
	width: 1.318vw;
	height: 2.344vh; }

.leaderboard-text-container {
	@include display-flex-center-mixin; }
