@import '~styles/mixins.sass';

.container {
	width: 14.641vw;
	border-radius: 15px;
	backdrop-filter: blur(5px);
	padding-bottom: 2vh; }

.disabled-container {
	zoom: 0.92; }

.rotate-image {
	width: 135px;
	height: 135px;
	position: absolute;
	top: 11px;
	left: 15px;
	opacity: 0.5; }

.title {
	width: 85%;
	height: 4.427vh;
	padding: 0vh 0.586vw 0.621vh 0.586vw;
	box-sizing: border-box;
	margin: auto;
	margin-top: 2vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center; }

.steps-container {
	margin: 1.7vh 0 0 2vw; }
