@import '~styles/mixins.sass';

.main-component {
	width: 49.634vw;
	min-height: 32.422vh;
	border-radius: 10px;
	overflow: hidden; }

.popup-header-container {
	width: 100%;
	height: 20vh;
	@include display-flex-center-mixin;
	position: relative;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	overflow: hidden; }

.popup-header-container-content {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box; }

.popup-header-container-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
 }	// height: 100%

.popup-details-container {
	width: 100%;
	height: 30%;
	box-sizing: border-box;
	padding: 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; }

.popup-buttons-container {
	width: 100%;
	height: 13.281vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center; }

.button-container {
	padding: 0 10px;
	display: flex;
	gap: 4vw; }

.header-image {
	padding: 1.5vh 1vw 1vh 1vw; }

.header-text {
	text-align: center;
	width: 100%;
	box-sizing: border-box; }

.header-desc {
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	padding: 0 20px 10px 20px; }

.popup-desc-container {
	box-sizing: border-box;
	padding: 1vh 3vw 0 3vw;
	text-align: center; }
