@import '~styles/mixins.sass';

.bar-container {
	width: 100%;
	height: 100%; }

.progressbar-container {
	position: relative; }

.progress-number {
	font-size: 28px;
	position: absolute;
	width: 100%;
	top: 35%;
	left: 20%;
	padding: 0;
	margin: 0; }
