@import '~styles/mixins.sass';

.main-component {
	width: 541px;
	height: 249px;
	border-radius: 10px;
	overflow: hidden; }

.popup-header-container {
	width: 100%;
	height: 12.76vh;
	@include display-flex-center-mixin;
	position: relative;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	overflow: hidden; }

.popup-header-container-content {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 24px; }

.popup-header-container-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
 }	// height: 100%

.popup-details-container {
	width: 100%;
	height: 30%;
	box-sizing: border-box;
	padding: 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; }

.popup-buttons-container {
	width: 100%;
	height: 102px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center; }

.button-container {
	padding: 0 10px; }

.header-image {
	width: 48px;
	height: 48px;
	margin-bottom: 16px; }

.header-text {
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0 10px 0; }

.header-desc {
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	padding: 0 20px 10px 20px; }
