@import '~styles/mixins.sass';

.sprint-footer-container {
	width: 100%;
	height: 5.8vh;
	box-sizing: border-box;
	position: relative; }

.sprint-footer-background {
	width: 100%;
	height: 100%;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start; }

.sprint-footer-component-content {
	width: 100%;
	height: 5.8vh;
	z-index: 2;
	position: absolute;
	bottom: -1.5vh;
	right: 4.6vw; }

.sprint-footer-component {
	display: flex;
	align-items: center;
	justify-content: flex-end; }
