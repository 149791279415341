@import '~styles/mixins.sass';

.header-container {
	@include display-flex-center-mixin;
	width: 100%;
	height: 8.3vh;
	min-width: 1280px;
	@extend %row;
	padding-right: 5.75vw;
	padding-left: 2.2vw;
	position: relative; }

.header-content {
	@include col-header;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	height: 100%; }

.left-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	padding: 0; }

.middle-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	padding: 0;
	margin-left: 2vw; }

.right-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	padding: 0;
	gap: 0.4vw; }
