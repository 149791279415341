@import '~styles/mixins.sass';

.action-wrapper {
	position: relative;
	top: 0;
	left: 0; }

.action-container {
	width: 100%;
	min-height: 4vh;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding-left: 0.2vw;
	border-radius: 4px; }

.action-container-story {
	width: 12.738vw;
	min-height: 4vh;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 2.73px; }

.action-image-container {
	width: 3.125vh;
	height: 3.125vh;
	@include display-flex-center-mixin; }

.action-image-story {
	width: 2.083vh;
	height: 2.083vh;
	@include display-flex-center-mixin; }

.action-image {
	width: 20px;
	height: 20px; }

.action-name {
	width: calc(100% - 36px);
	height: 100%;
	box-sizing: border-box;
	padding-left: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.disable-action {
	pointer-events: none;
	opacity: 0.5; }


