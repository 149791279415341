@import '~styles/mixins.sass';

.option-container {
	display: flex;
	width: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0.5vh 0.5vw;
	margin-bottom: 1vh;
	cursor: pointer; }

.radio-container {
	width: 2.083vh;
	display: flex;
	align-items: flex-end;
	margin-bottom: 3vh;
	padding-right: 5px; }


.option-details-container {
	width: 100%; }

.radio-outline {
	@include display-flex-center-mixin;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	box-sizing: border-box;
	margin-top: 5px; }

.radio-inner-circle {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	box-sizing: border-box; }

.option-effort-cost-container {
	@include display-flex-space-between;
 }	// margin-top: 5px

.option-effort-cost-content {
	@include display-flex-start; }

.option-effort-cost-title {
	// padding-right: 5px
	margin-right: 5px; }

