@import '~styles/mixins.sass';

.main-component {
	width: 54.539vw;
	position: relative; }

.header-container {
	padding: 0.5vw 0.5vw 0.3vw 2vw;
	display: flex;
	justify-content: space-between;
	margin-top: 8vh;
	align-items: center;
	height: 6.771vh; }

.close-icon {
	position: relative;
	z-index: 1;
	margin-right: 4vw;
	height: 4.167vh; }

.popup-body {
	padding: 0.5vw 0.5vw 0.3vw 2vw;
	width: 46.925vw;
	height: 75vh;
	overflow-x: none; }

.footer-svg {
	width: 100%;
	position: absolute;
	left: 0px;
	bottom: -0.5vh; }

.team-members-container {
	display: flex;
	justify-container: start;
	gap: 5px;
	width: 100%;
	margin-top: 2vh; }

.card-style {
	margin: 2px 2px;
	width: 23.133vw;
	text-align: center; }

.card-header {
	display: flex;
	justify-content: space-around; }

.tm-member-details {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1.5vh; }

.img-wrapper {
	height: 16.2vh;
	width: 8.785vw; }

.tm-member-text-details {
	height: 7.943vh;
	display: flex;
	margin-top: 1vh; }

.name-details {
	font-weight: bold; }

.tm-morale-stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 40px; }

.tm-skill-stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 45px; }

.stat-value {
	padding: 1.5px 12px;
	border-radius: 10px; }

.skill-stats-header {
	display: flex;
	align-items: center; }

.skill-icon {
	width: 11px;
	height: 11px;
	margin-right: 5px; }
