@import '~styles/mixins.sass';

.sim-progress-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0px 12px 0vw;
	box-sizing: border-box;
	height: 5.199vh; }

.sim-progress-child {
	width: 23.4vw; }

.sim-progress-child {
	position: absolute;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%); }

.sim-progress-content {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 0.3vw 0px 0.8vw;
	margin-top: 5px;
	position: relative;
	height: 5.199vh; }

.progress-start-text {
	padding-right: 5px;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.progress-end-text {
	padding-left: 5px;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	padding-bottom: 5px; }

.progress-container {
	display: flex; }


.end-days-container {
	width: 2px;
	height: 10px;
	margin-right: 1px;
	border-radius: 2px;
	box-sizing: border-box; }

.middle-days-container {
	width: 2px;
	height: 6px;
	margin-right: 1px;
	border-radius: 2px;
	box-sizing: border-box; }

.current-days-container {
	width: 2px;
	height: 8px;
	margin-right: 1px;
	border-radius: 2px;
	box-sizing: border-box; }

.day-tag-container {
	position: absolute;
	width: 45px;
	height: 14px;
	border-radius: 4px;
	@include display-flex-center-mixin; }

.sprint-section-container {
	width: 28%; }


.sprint-container {
	display: flex;
	width: 4.3vw;
	height: 1.8vh;
	box-sizing: border-box;
	padding: 2px;
	justify-content: space-evenly;
	gap: 3px;
	margin-right: 1px; }

.progress-value {
	display: flex;
	margin-right: 8px; }

.deadline-bar-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	gap: 5px; }

.deadline-container {
	display: flex; }
