@import '~styles/mixins.sass';

.loader-container {
	@include display-flex-center-mixin;
	flex-direction: column;
 }	// position: relative

.svg-container {
	@include display-flex-center-mixin;
	// position: absolute
	-webkit-animation: loadingMove 3s infinite;
	animation: loadingMove 3s infinite;
	width: 103px;
	height: 92px;
	margin-bottom: 10px; }

.svg-wrapper {
	width: 100%;
	height: 100%; }

@keyframes loadingMove {
  0%,100% {
    transform: rotateY(0deg); }

  50% {
    transform: rotateY(180deg); } }
