@import '~styles/mixins.sass';

.container {
	@include display-flex-center-mixin;
	width: 100%;
	min-height: 38.021vh;
	box-sizing: border-box;
	position: relative;
	padding: 0px 1vw 10px 5vw;
	box-sizing: border-box;
	top: 0;
	left: 0;
	@extend %row; }

.image-container {
	@include display-flex-center-mixin;
	width: 25%;
	height: 28.516vh;
	border-radius: 1px;
	box-sizing: border-box; }

.image {
	width: 21.318vw;
	height: 28.516vh; }

.response-container {
	width: 75%;
	// height: 100%
	padding: 0px 0px 3.906vh 0.952vw;
	box-sizing: border-box; }

.response-message {
	width: 100%;
	box-sizing: border-box;
	padding-right: 8vw; }

.person-name {
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 0px;
	box-sizing: border-box; }

.close-container {
	position: absolute;
	bottom: 7vh;
	right: 3%; }

.close-container .continue {
	width: 50px; }

.metrics-container {
	@include display-flex-start;
	height: 5.208vh;
	margin-right: 1.464vw;
	padding-right: 0.3vw;
	margin-top: 7vh; }

.left-metric-container {
	min-height: 67px;
	border-radius: 18px;
	margin: 0 8px;
	padding: 10px 18px;
	box-sizing: border-box;
	min-width: 175px; }

.left-metrics-value-change-container {
	@include display-flex-start;
	margin-top: 3px; }

.metric-title {
	text-transform: uppercase; }

.metrics-image {
	width: 1.171vw;
	height: 2.083vh;
	margin-right: 8px; }

.metrics-value {
	margin-right: 12px; }

.metrics-change {
	@include display-flex-center-mixin;
	min-width: 3.058vw;
	height: 3.058vh;
	padding: 0 5px;
	border-radius: 12px;
	box-sizing: border-box; }

.arrow-container {
	width: 1.171vw;
	height: 2.083vh;
	margin-right: 3px; }


.metric-container {
	@include display-flex-center-mixin;
	height: 6.5vh;
	min-width: 5.792vw;
	margin-right: 20px; }

.metric-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 3px;
	height: 6.1vh;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 4px 6px; }

.metric-name-desc-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.metric-name-value-container {
	display: flex;
	flex-direction: column;
	align-itmes: start;
	justify-content: space-between;
	padding-top: 8px; }


.metric-name {
	padding-right: 1px; }

.metric-image-container {
	display: flex;
	height: 100%;
	align-items: center; }

.metric-image {
	width: 24px;
	height: 24px; }

.metric-value {
	padding-left: 0px;
	margin-top: -0.6vh; }

.metric-value-right {
	margin-right: 3px; }
