@import '~styles/mixins.sass';

.story-popup-container {
	position: relative; }

.header-container {
	width: 51.835vw;
	min-height: 14vh;
	position: relative; }

.header-without-story {
	min-height: 9vh;
	width: 59.327vw; }

.content-container {
	width: 51.835vw;
	// height: calc(100% - 81px)
	min-height: 20.052vh;
	// @include display-flex-center-mixin
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	box-sizing: border-box;
	padding-bottom: 20px; }

.content-container-blocker {
	min-height: 42.188vh;
	width: 59.327vw; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.header-content-container {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	padding-top: 5px; }

.header-details-container {
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 1.1vw; }

.story-name {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.story-details {
	width: 100%;
	height: 20%;
	display: flex;
	margin-top: 1vh;
	justify-content: flex-start;
	align-items: center; }

.close-container {
	width: 5.857vw {
    height: 0.763vh; }
	margin-bottom: 4vh;
	margin-right: 20px;
	border-radius: 2px; }

.close-container-blocker {
	width: 5.857vw {
    height: 0.763vh; }
	margin-bottom: 1.8vh;
	margin-right: 20px;
	border-radius: 2px; }

.close-icon {
	width: 5.857vw {
    height: 0.763vh; } }

.each-detail-container {
	@include display-flex-center-mixin;
	padding-right: 1.464vw; }

.each-detail-name {
	padding-right: 5px; }

.each-detail-value {
	padding-right: 10px; }

.each-detail-info {
	width: 10px;
	height: 10px; }

.story-description-container {
	width: 75%;
	height: 35%;
	box-sizing: border-box;
	padding: 1.302vh 1.1vw; }

.story-history-container {
	width: 70%;
	height: 50%;
	box-sizing: border-box;
	padding: 10px 1.1vw; }

.story-description-container-full {
	width: 100%;
	height: 35%;
	box-sizing: border-box;
	padding: 10px 24px; }

.story-history-container-full {
	width: 100%;
	height: 50%;
	box-sizing: border-box;
	padding: 24px; }

.story-details-container {
	width: 70%;
	height: 20%;
	box-sizing: border-box;
	padding: 10px 24px; }

.story-actions-container {
	box-sizing: border-box;
	padding: 10px 0 10px 24px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	top: 0;
	right: -2.2vw;
	z-index: 2;
	overflow-y: auto; }

.story-description-heading {
	width: 100%;
	text-align: left;
	padding-bottom: 10px; }

.story-description-content {
	width: 90%;
	text-align: left; }

.feature-description-heading {
	width: 100%;
	text-align: left;
	padding-bottom: 10px;
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.feature-description-content {
	width: 100%;
	text-align: left;
	padding-bottom: 10px; }

.story-history-heading {
	width: 100%;
	text-align: left;
	padding-bottom: 10px;
	height: 16px; }

.story-history-content {
	width: 100%;
	// height: calc(100% - 26px)
	height: 100%;
	overflow-y: auto; }

.feature-image {
	width: 20px;
	height: 20px;
	margin-right: 10px; }

.selection-container {
	box-sizing: border-box;
	position: absolute;
	bottom: 4vh;
	right: 1.5vw; }

.selection-content {
	cursor: pointer;
	box-sizing: border-box;
	padding: 10px;
	height: 100%;
	width: 10.249vw;
	@include display-flex-center-mixin; }

.checkbox-container {
	width: 18px;
	height: 18px; }

.text-container {
	padding-left: 4px; }

.card-tag {
	border-bottom-right-radius: 2px; }

.card-tag {
	display: flex;
	position: absolute;
	justify-content: space-evenly;
	z-index: 2;
	top: 0px;
	left: 1.1vw;
	padding-left: 0.1vw;
	padding-right: 0.2vw;
	gap: 2px;
	align-items: center;
	border-bottom-left-radius: 2px; }


.card-tag-image {
	width: 1.171vw;
	object-fit: cover; }

.card-tag-text {
	display: flex;
	line-height: 2.446vh;
	font-weight: 600;
	font-size: 0.875em; }

.story-select {
	position: absolute;
	top: 105px;
	left: 500px;
	width: 10.703vw;
	height: 4.893vh;
	display: flex;
	align-items: center;
	justify-content: space-evenly; }

.story-select-icon {
	width: 11px; }

.story-select-text {
	font-size: 12px; }

.priority-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content:space-between {}
	height: 1.171vw;
	border-radius: 2px;
	padding: 0px 0.293vw 0 0.293vw; }

.priority-text {
	display: flex;
	font-weight: 400;
	font-size: 0.625em; }

.priority-image-container {
	width: 1.171vw; }
