@import '~styles/mixins.sass';

.main-component {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #DADADA; }

