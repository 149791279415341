@import '~styles/mixins.sass';

.objectives-container {
	height: 5.199vh;
	width: 8.39vw;
	padding: 8px 0.5vw 8px 0.5vw;
	box-sizing: border-box;
	border-radius: 4px;
	@include display-flex-center-mixin;
	cursor: pointer;
	justify-content: space-evenly;
	gap: 3px;
	margin-right: 0.5vw; }

.objectives-image-container {
	width: 1.464vw;
	height: 100%;
	@include display-flex-center-mixin; }

.objectives-image {
	width: 1.318vw;
	height: 2.344vh; }

.objectives-text-container {
	@include display-flex-center-mixin; }
