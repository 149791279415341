@import '~styles/mixins.sass';

.sim-logo-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sim-logo {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sim-name {
	padding-left: 5px; }

.sim-image {
	width: 17.7vw;
	height: 6.881vh;
	margin-left: 6.881vh; }
