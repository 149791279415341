@import '~styles/mixins.sass';

.tooltip-container-bottom {
    width: 18.302vw;
    position: absolute;
    top: 5.8vh;
    left: -29vw;
    z-index: 51;
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.tooltip-arrow-top-bottom {
	width: 0;
	height: 0;
	margin-top: 8px; }

.tooltip-content {
    box-sizing: border-box;
    padding: 7px;
    width: 100%;
    text-align: left; }

.info-icon {
    width: 100%;
    height: 100%; }

.user-profile-container-with-options {
	@include display-flex-center-mixin;
	position: relative;
	outline: none; }

.user-profile-container {
	@include display-flex-center-mixin;
	cursor: pointer; }

.user-profile-image-container {
	width: 2vw;
	height: 2vh; }

.user-profile-arrow-container {
	margin-left: 0.4vw;
	width: 0.777vw;
	height: 2.446vh; }

.user-profile-option-container {
	position: absolute;
	width: 13.836vw;
	height: 4.2vw;
	z-index: 50;
	top: 5.3vh;
	left: -11vw;
	overflow: hidden;
	padding-top: 0.3vh; }

.each-option {
	width: 100%;
	padding: 1vh 1vw;
	box-sizing: border-box; }

.user-name-container {
	height: 4vh;
	width: 100%;
	box-sizing: border-box;
	padding: 0.5vh 1vw; }


.user-name {
	height: 50%;
	width: 100%;
	text-align: left;
	padding: 5px 0;
	word-break: break-all; }
