@import '~styles/mixins.sass';

.leaderboard-popup-container {
	width: 67vw;
	height: 77vh;
	box-sizing: border-box; }

.header-container {
	width: 100%;
	height: 6.5vh;
	z-index: 2;
	position: relative; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.title {
	//padding: 1.6vh 0 0 1.2vw
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-left: 1.2vw;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	text-size: 30px; }

.close-icon {
	position: absolute;
	top: 1.2vh;
	right: 1vw;
	z-index: 1; }

.refresh-container {
	position: absolute;
	top: 0.7vh;
	right: 8vw;
	z-index: 1; }


.table-header-container {
	width: 100%;
	text-transform: uppercase;
	// @include display-flex-start
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-top: 10px; }

.position {
	min-width: 100px;
	box-sizing: border-box; }

.username {
	min-width: 520px;
	box-sizing: border-box; }

.days-remaining {
	min-width: 145px;
	box-sizing: border-box; }

.mvp {
	min-width: 75px;
	box-sizing: border-box; }

.position-header {
	padding-left: 22px; }

.position-body {
	@include display-flex-center-mixin; }

.mvp-body {
	text-align: right; }

.table-body-container {
	height: calc(100% - 25px);
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden; }

.table-row {
	@include display-flex-horizontal-start;
	padding: 12px 0; }

.seperation-line {
	width: calc(100% - 32px);
	box-sizing: border-box;
	margin: 0 16px;
	height: 1px; }


.table-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%; }

.map-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 97%; }

.map-image {
	height: 95%;
	width: 97%;
	overflow: auto; }


.area-name {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 1.5em;
	gap: 0.5vw; }
