@import '~styles/mixins.sass';

.leaderboard-popup-container {
	width: 67.2vw;
	height: 68.7vh;
	box-sizing: border-box; }

.header-container {
	width: 100%;
	height: 8vh;
	z-index: 2;
	position: relative; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.title {
	padding: 2.6vh 0px 0px 1.4vw;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0; }

.close-icon {
	position: absolute;
	top: 1.5vh;
	right: 1vw;
	height: 5vh;
	z-index: 1;
	margin-top: 0.4vh; }

.refresh-container {
	position: absolute;
	top: 1.7vh;
	right: 7vw;
	z-index: 1;
	margin-right: 0.5vw; }

.content {
	width: 100%;
	height: calc(100% - 116px);
	position: relative;
	top: 0;
	left: 0; }

.table-header-container {
	width: 100%;
	height: 4vh;
	text-transform: uppercase;
	// @include display-flex-start
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 2vh 0px 0px 0px; }

.position {
	width: 8vw;
	box-sizing: border-box; }

.user-container {
	display: flex;
	flex-direction: row;
	margin-top: 1vh;
	padding-right: 10px; }

.username-heading {
	width: 39vw;
	box-sizing: border-box;
	padding-left: 1vw; }


.username {
	width: 41vw;
	box-sizing: border-box;
	padding-left: 1vw; }

.days-remaining {
	width: 10vw;
	box-sizing: border-box; }

.mvp {
	box-sizing: border-box; }

.position-header {
	padding-left: 2.4vw; }

.position-body {
	@include display-flex-center-mixin; }

.mvp-body {
	text-align: right; }

.table-body-container {
	height: 49vh;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 1vh; }

.table-row {
	@include display-flex-horizontal-start;
	height: 4vh;
	padding-top: 2vh; }

.seperation-line {
	width: calc(100% - 32px);
	box-sizing: border-box;
	margin: 0 16px;
	height: 1px; }


.table-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%; }

.button-wrapper {
	display: flex;
	align-items: center; }
