@import '~styles/mixins.sass';
.b-msg {
	padding: 0 15px;
	font-size: 0.875em;
	color: rgba(3,26,32, 0.6);
	margin-top: 8px; }

.container {
	height: 89.3vh;
	width: 53vw;
	box-sizing: border-box;
	background: linear-gradient(180deg, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
	margin-top: 8vh; }

.header {
	height: 6.771vh;
	width: 100%;
	background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
	display: flex;
	justify-content: space-between;
	align-items: center; }

.header-content {
	font-size: 1.75em;
	padding-top: 1vh;
	font-weight: 600;
	color: white;
	padding-left: 2vw; }

.list {
	margin-left: 5.857vw;
	width: 38vw;
	z-index: 1; }
.day {
	position: relative;
	min-height: 13.021vh;
	margin-left: 1vw;
	border-left: 2px solid rgba(152, 152, 152, 1); }
.number {
	position: absolute;
	left: -5.124vw;
	font-size: 0.875em;
	font-weight: 600;
	color: rgba(51, 51, 51, 1); }

.box {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: calc(100% - 13vh);
	box-sizing: border-box;
	opacity: 0.95;
	z-index: 0; }

.footer-svg {
	display: flex;
	position: fix;
	margin-top: -10vh;
	z-index: 0; }

.close {
	position: absolute {
    width: 1.83vw;
    height: 3.255vh; }
	top: 9.7vh;
	right: 5vw; }

.line {
	width: 13.021vw; }

.circle {
	background: #FFFFFF;
	border: 1px solid rgba(151, 32, 130, 1);
	box-sizing: border-box;
	border-radius: 50%;
	height: 17px;
	width: 17px;
	position: absolute;
	left: -9px; }

.action {
	width: 48.829vw;
	// height: 143px
	border-top: 2px solid rgba(251, 189, 14, 1);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	position: relative;
	padding: 10px;
	margin-bottom: 15px; }

.event {
	// min-height: 143px
	width: 48.829vw;
	border-top: 2px solid rgba(151, 32, 130, 1);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	position: relative;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px; }

.blocker {
	// height: 143px
	width: 17.57vw;
	border-top: 2px solid rgba(209, 16, 33, 1);
	background: #FFFFFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	border-radius: 8px;
	margin-left: 15px;
	position: relative;
	padding: 10px;
	margin-bottom: 15px; }



.blocker-msg {
	// height: 143px
	width: 411px;
	border-top: 2px solid rgba(209, 16, 33, 1);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px; }

.header-tag {
	display: flex;
	align-items: center; }

.spacer {
	padding: 2px 0px;
	display: flex; }
.a-hash {
	font-size: 0.75em;
	color: rgba(251, 189, 14, 1);
	font-weight: bold; }

.sprint-day-tag {
	padding: 2px 7px;
	background: rgba(2, 26, 32, 0.07);
	color: rgba(2, 26, 32, 0.7);
	font-size: 0.75em;
	margin-left: 10px;
	border-radius: 8px; }

.e-hash {
	font-size: 0.75em;
	color: rgba(151, 32, 130, 1);
	font-weight: bold; }

.b-hash {
	font-size: 0.75em;
	color: rgba(209, 16, 33, 1);
	font-weight: bold; }

.a-name {
	font-weight: 600;
	font-size: 0.875em;
	color: rgba(0, 0, 0, 1); }

.action-option-name {
	font-weight: 600;
	font-size: 0.875em;
	color: rgba(51, 51, 51, 1);
	margin-top: 2px; }

.action-option {
	height: 2.604vh;
	font-weight: 350;
	line-height: 2.604vh;
	font-size: 0.875em;
	margin-top: 2px;
	color: rgba(51, 51, 51, 1); }

.e-msg {
	font-size: 0.875em;
	color: rgba(51, 51, 51, 1);
	margin-top: 1.042vh; }
.met-box {
	display: flex;
	margin-top: 1.302vh; }

.met-box-eve {
	margin-top: 1.953vh; }

.metric {
	height: 2.804vh;
	border-radius: 16px;
	text-align: center;
	font-weight: 600;
	font-size: 1em;
	line-height: 2.083vh;
	margin: 1vh;
	display: flex;
	align-items: center;
	justify-content {} }

.arrow-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1vh; }

.metric-diff {
	display: flex;
	font-size: 0.75em;
	font-weight: 700;
	justify-content: center; }

.met-value {
	display: flex;
	font-size: 1em;
	flex-direction: column;
	justify-content: center;
	font-weight: 600;
	width: 1.977vw;
	margin-left: 0.2vw; }

.met-container {
	margin-right: 0.732vw;
	display: flex;
	height: 5.208vh;
	border: 1px solid rgba(152, 152, 152, 1);
	border-radius: 4px; }

.met-icon {
	display: flex;
	width: 1.757vw;
	margin-left: 0.22vw;
	margin-right: 0.146vw; }

.met-name {
	font-size: 0.725em;
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
	text-transform: capitalize;
	margin-left: 0.293vw;
	margin-right: 0.19vw; }

.arrow {
	width: 1.171vw; }

.filter {
	height: 6vh;
	display: flex;
	margin-left: 2vw;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	div:first-child {
		font-size: 1em;
		color: #031A20;
		display: flex;
		justify-content: center; } }

.filter-by {
	margin-right: 0.586vw;
	display: flex;
	justify-content: center; }

.line-separator {
	width: 96.4%;
	height: 1px;
	background-color: #989898;
	opacity: 1;
	margin-left: auto; }

.filter-box {
	display: block;
	cursor: pointer;
	border: 1px solid rgba(238, 119, 72, 1);
	padding: 4px 10px;
	border-radius: 4px;
	margin-left: 0.4vw;
	box-sizing: border-box;
	font-size: 0.75em;
	@include display-flex-center-mixin; }

.filter-name {
	margin-right: 0px; }

.slected {
	background-color: rgba(238, 119, 72, 1); }
