@import '~styles/mixins.sass';
.comment {
    width: calc(100% - 50px);
    font-size: 14px;
    line-height: 25px;
    color: rgba(3,26,32, 0.7);
    // border-bottom: 1px solid #232882
    min-height: 34px; }
.heading {
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	text-transform: uppercase;
	color: rgba(35,40,130,0.6); }
.top {
    display: flex;
    margin-bottom: 5px; }
.avatar-wrap {
    margin-left: 28px;
    margin-top: -6px;
    img {
        height: 35px;
        width: 35px; } }

.comment-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(35, 40, 120, 0.2);
    padding: 5px 0; }

.comment-image {
    // width: 20%
    @include display-flex-center-mixin;
    // align-items: flex-start
    width: 40px;
    margin: 2px 10px 0 10px;
    position: relative; }

.normal-chat-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: -7px;
    left: -12px; }
