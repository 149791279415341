@import '~styles/mixins.sass';

.intro-popup-container {
	width: 88.287vw;
	height: 82.552vh;
	box-sizing: border-box; }

.intro-popup-container2 {
	width: 88.287vw;
	height: 42.708vh;
	box-sizing: border-box; }

.header-container {
	width: 100%;
	height: 7.292vh;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0; }

.header-background-container {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%; }

.header-content {
	position: relative;
	padding-top: 1.5vh;
	z-index: 1; }

.title {
	position: absolute;
	left: 2vw;
	z-index: 1; }

.close-icon {
	position: absolute;
	right: 1vw;
	top: 1.6vh;
	z-index: 1; }

.content {
	width: 100%;
	height: 90%;
	position: relative;
	display: flex;
	flex-direction: column; }

.intro-content-container {
	z-index: 3;
	overflow-y: auto;
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	flex-direction: row; }

.button-container {
	display: flex;
	flex-direction: row;
	z-index: 3;
	position: absolute;
	bottom: 6vh;
	right: 1vw; }

.button-container2 {
	display: flex;
	flex-direction: row;
	z-index: 3;
	position: absolute;
	bottom: 3.6vh;
	right: 1vw; }

.content-background-1 {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0; }

.content-background-2 {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0; }

.slide-intro {
	padding-top: 2vh;
	margin: 0.1vh 1vw 1vw 2vw; }

.intro-content-container2 {
	display: flex;
	position: absolute;
	top: 25vh;
	right: 0.4vw;
	z-index: 1; }


.transcript-container {
	padding: 15px 50px;
	box-sizing: border-box;
	width: 100%; }

.transcript-show {
	padding-top: 5px;
	display: block;
	width: 100%; }

.transcript-hidden {
	padding-top: 5px;
	display: none; }

.transcript {
	cursor: pointer;
	display: flex; }

.video-container {
	// height: 400px
	width: calc(100% - 40px);
	@include display-flex-center-mixin;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	background: black;
	padding: 20px 0;
	margin: 0 20px;
	box-sizing: border-box;
	overflow-x: hidden;
 }	// box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.1)

.video {
	background-color: black; }

.icon {
	height: 8px;
	width: 12px;
	padding-top: 10px; }

.playing-conditions-container {
	width: 100%;
	box-sizing: border-box;
	padding: 20px 40px;
	// position: absolute
	top: 0;
	left: 0;
 }	// z-index: 4



.sprint-plan-header-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 2vw;
	margin-right: 7vw; }

.sprint-story-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-left: 2vw;
	margin-right: 3vw; }

.sprint-stories-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 7vh;
	overflow-y: scroll;
	margin-top: 0.5vh;
	padding-left: 0.13vw;
	padding-right: 0.2vw; }

.sprint-plan-sprint-story-container {
	width: -webkit-fill-available;
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	// margin-left: 5vw
	margin-right: 1.2vw;
	margin-top: 2vh;
	scroll-behavior: 'smooth';
	overflow-x: hidden;
	padding-right: 0.2vw; }

.header {
	box-sizing: border-box;
	padding: 2vh 3vw;
	width: 91vw;
	display: flex;
	flex-direction: column;
	align-items: center; }


.header-title {
	// cursor: pointer
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%; }

.header-desc {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding-top: 1.5vh; }

.sprint-number {
	color: #fff;
	height: 3vh;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 2vh;
	font-size: 0.875em;
	margin-top: 1.223vh;
	margin-left: 0.5vw;
	padding: 0.5vh 0.5vw; }

.sprint-story {
	height: 10vh;
	margin-bottom: 1vh; }

.card-container {
	width: 6vw {
  height: 6vh; } }

.sprint-button-container {
	width: 90vw;
	margin-left: 5vw;
	margin-right: 3vw;
	margin-top: -3vh; }

.card-container {
	width: 15.596vw;
	height: 16.667vh;
	box-sizing: border-box;
	// padding: 11px 0
	margin: 0 7px 10px 5px;
	position: relative;
	margin-right: 1vw; }

.card-tag {
	display: flex;
	position: absolute;
	gap: 0.1vw;
	justify-content: space-evenly;
	z-index: 2;
	top: 0px;
	left: 0.5vw;
	align-items: center;
	padding: 0vh 0.3vw 0.1vh 0.1vw; }

.card-tag-image {
	width: 1.171vw;
	display: flex;
	align-items: center;
	justify-content: center; }

.card-tag-text {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 12px; }


.card-header {
	line-height: 2.446vh;
	width: 14.297vw;
	font-weight: 600;
	font-size: 12px; }


.card-content {
	width: 14.373vw;
	height: 7.645vh;
	margin-top: 3.211vh;
	margin-left: 0.612vw; }

.card-description {
	display: flex;
	width: 14.297vw;
	line-height: 2.141vh;
	margin-top: 3px;
	padding-top: 4px;
	font-weight: 350;
	font-size: 10px; }

.card-bottom-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 3px;
	width: 95%;
	margin-left: 4px;
	justify-content: space-between; }

.story-point-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	margin-left: 4px; }

.story-point-text {
	line-height: 2.141vh;
	font-weight: 500;
	font-size: 0.625em; }

.story-point-number {
	font-weight: 600;
	font-size: 10px; }

.story-point-icon {
	display: flex;
	width: 8px;
	height: 8px; }

.priority-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content:space-between {}
	height: 1.171vw;
	padding: 0px 0.293vw 0px 0.293vw;
	border-radius: 2px; }

.priority-text {
	display: flex;
	font-weight: 400;
	font-size: 10px; }
