@import '~styles/mixins.sass';

.intro-content-container {
	padding-top: 25px;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	box-sizing: border-box; }

.sprint-plan-header-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start; }

.sprint-story-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 88.287vw;
	margin: auto; }

.sprint-stories-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 7vh;
	overflow-y: scroll;
	margin-top: 0.5vh;
	padding-left: 0.13vw;
	padding-right: 0.2vw; }

.sprint-plan-sprint-story-container {
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	// margin-left: 5vw
	margin-top: 2vh;
	scroll-behavior: 'smooth';
	backdrop-filter: blur(5px);
	padding-right: 0.2vw; }

.header {
	box-sizing: border-box;
	padding: 2vh 1.5vw;
	width: 88.287vw;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	backdrop-filter: blur(5px); }


.header-title {
	// cursor: pointer
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%; }

.header-desc {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding-top: 1.5vh; }

.sprint-number {
	color: #fff;
	height: 3.125vh;
	width: 4.8vw;
	line-height: 3.058vh;
	font-size: 14px;
	margin-top: 1.223vh;
	margin-left: 0.5vw;
	padding: 2px 0px 2px 0px;
	display: flex;
	align-items: center;
	justify-content: center; }

.sprint-story {
	height: 10vh;
	margin-bottom: 1vh; }

.card-container {
	width: 6vw {
  height: 6vh; } }

.sprint-button-container {
	width: 88vw;
	margin: auto;
	margin-top: -3vh; }

.card-container {
	width: 15.596vw;
	height: 16.667vh;
	box-sizing: border-box;
	// padding: 11px 0
	margin: 0 7px 10px 5px;
	position: relative;
	margin-right: 0.2vw; }

.card-tag {
	display: flex;
	position: absolute;
	gap: 0.1vw;
	justify-content: space-evenly;
	z-index: 2;
	top: 0px;
	left: 0.5vw;
	align-items: center;
	padding: 0vh 0.3vw 0.1vh 0.1vw;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px; }

.card-tag-image {
	width: 1.171vw;
	display: flex;
	align-items: center;
	justify-content: center; }

.card-tag-text {
	display: flex;
	font-weight: 600;
	font-size: 0.75em;
	align-items: center; }


.card-header {
	line-height: 2.446vh;
	width: 14.297vw;
	font-weight: 600;
	font-size: 0.75em; }


.card-content {
	width: 14.373vw;
	height: 7.645vh;
	margin-top: 3.211vh;
	margin-left: 0.4vw; }

.card-description {
	display: flex;
	width: 14.297vw;
	line-height: 2.141vh;
	margin-top: 3px;
	padding-top: 4px;
	font-weight: 500;
	font-size: 0.625em; }

.card-bottom-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 3px;
	width: 95%;
	margin-left: 4px;
	justify-content: space-between; }

.story-point-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	margin-left: 4px; }

.story-point-text {
	line-height: 2.141vh;
	font-weight: 500;
	font-size: 0.625em; }

.story-point-number {
	font-weight: 600;
	font-size: 0.625em; }

.story-point-icon {
	display: flex; }

.priority-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content:space-between {}
	height: 1.171vw;
	padding: 0px 0.293vw 0 0.293vw;
	border-radius: 2px; }

.priority-text {
	display: flex;
	font-weight: 400;
	font-size: 0.625em; }

.priority-image-container {
	width: 1.171vw;
	display: flex;
	align-items: center; }

.priority-image {
	width: 100%;
	height: 100%;
	object-fit: cover; }
