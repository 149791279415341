@import '~styles/mixins.sass';

.complete-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	box-sizing: border-box;
	z-index: 50; }

.bottom-container {
	display: flex;
	align-items: flex-end;
	justify-content: center; }

.bottom-container-component {
	min-height: 100px;
	width: 100%; }

.right-container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start; }

.center-container {
	display: flex;
	justify-content: center;
	align-items: center; }

.right-container-component {
	min-width: 500px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }
