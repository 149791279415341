@import '~styles/mixins.sass';

.timer-container {
	height: 65%;
	width: 5.887vw;
	margin-right: 0.4vw;
	margin-left: 0.4vw;
	overflow: hidden;
	@include display-flex-center-mixin; }

.timer-content {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	padding: 0 5px 5px 5px;
	box-sizing: border-box;
	text-align: center {
    overflow: hidden;
    white-space: nowrap; } }

.timer-content-ar {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	box-sizing: border-box; }
