@import '~styles/mixins.sass';

.actions-list-container {
	width: 16.5vw;
	// z-index: 1
	// overflow-y: auto
	// margin: auto
	padding-top: 1vh; }

.each-action-container {
	margin-bottom: 1.302vh; }

.each-action-container:last-child {
  overflow-y: hidden; }

.overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
 }	// z-index: -1
